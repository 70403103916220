import styles from './LoginRegister.module.scss';
import PageContainer from 'src/layout/Page/PageContainer/PageContainer';
import HeaderSelfCare from 'src/layout/Header/Header.selfcare';
import InputText from '@components/Inputs/InputText/InputText';
import Button from '@components/Button/Button';
import { useEffect, useState } from 'react';
import FooterLinks from 'src/layout/SelfCare/Footer/FooterLinks';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuthWithKratosSelfCare } from 'src/Hooks/useAuthWithKratosSelfCare';
import { LoginFlow, UiNodeInputAttributes, UiNodeInputAttributesTypeEnum } from '@ory/client';
import { useForm } from 'react-hook-form';
import { Oval } from 'react-loader-spinner';
import AlertBox from '@components/AlertBox/AlertBox';

export default function LoginRegisterEmailPassword({
  isRegister,
}: {
  isRegister?: boolean;
}) {

  const [loadingLoginOrRegister, setLoadingLoginOrRegister] = useState(false);
  const { orySdk, loading, onLogin, onRegister } = useAuthWithKratosSelfCare();

  // Get param in query
  const [searchParams] = useSearchParams();
  const return_to = searchParams.get("return_to");

  const navigate = useNavigate();
  const [oryLoginRegisterFlow, setOryLoginRegisterFlow] = useState<LoginFlow | null>(null);
  const form = useForm();

  const onSubmitKratos = (data: any) => {

    const action = oryLoginRegisterFlow?.ui?.action;
    if (!action) {
      return;
    }
    setLoadingLoginOrRegister(true);

    if (!isRegister) {
      orySdk.updateLoginFlow({
        flow: oryLoginRegisterFlow.id,
        updateLoginFlowBody: {
          ...data,
          method: "password",
        },
      }).then(({ data }) => {
          onLogin(data);
          if (return_to) {
            navigate(return_to);
            return;
          }
          navigate("../");
        })
        .catch((error) => {
          setOryLoginRegisterFlow(error.response.data);
        }).finally(() => {
          setLoadingLoginOrRegister(false);
        });
    } else {
      orySdk.updateRegistrationFlow({
        flow: oryLoginRegisterFlow.id,
        updateRegistrationFlowBody: {
          ...data,
          method: "password",
        },
      }).then(({ data }) => {
          onRegister(data);
          if (return_to) {
            navigate(return_to);
            return;
          }
          navigate("../");
        })
        .catch((error) => {
          setOryLoginRegisterFlow(error.response.data);
        }).finally(() => {
          setLoadingLoginOrRegister(false);
        });
    }


  };

  useEffect(() => {
    if (loading) return;

    // reset
    setOryLoginRegisterFlow(null);
    form.reset();

    if (isRegister) {
      // Create login or register Flow
      orySdk
        .createBrowserRegistrationFlow().then(({ data }) => {
          setOryLoginRegisterFlow(data);
        })
        .catch((error) => {
          console.error("Error creating login flow", error);
        });
    } else {
      // Create login or register Flow
      orySdk
        .createBrowserLoginFlow().then(({ data }) => {
          setOryLoginRegisterFlow(data);
        })
        .catch((error) => {
          console.error("Error creating login flow", error);
        });
    }

  }, [isRegister, orySdk, loading, form])

  return (
    <PageContainer
      containerProps={{
        className: [styles.container, 'selfcare'].join(" "),
      }}
    >

      <HeaderSelfCare
        displayBackButton={true}
        onBackButtonClick={() => {
          navigate(-1);
        }}
        displayWarrantiesButton={false}
        title={isRegister ? "Création de compte" : "Connexion"}
      />

      <div className={styles.content}>
        {oryLoginRegisterFlow?.ui?.messages?.[0]?.text && (
        <AlertBox
          text={oryLoginRegisterFlow?.ui?.messages?.[0]?.text}
          type="error"
          displayIcon
          containerProps={{
            className: "w-full mb-4"
          }}
        />
        )}

        {oryLoginRegisterFlow?.ui && (
        <form className={styles.formContainer} onSubmit={form.handleSubmit(onSubmitKratos)}>
          {oryLoginRegisterFlow.ui.nodes.map((node) => {

            if (node.attributes.node_type !== "input") {
              return null;
            }

            const attributes = node.attributes as UiNodeInputAttributes;

            switch (attributes.type) {
              case 'text':
              case 'email':
              case 'password':
                return (
                  <InputText
                    key={attributes.name}
                    label={node.meta.label?.text || attributes.name}
                    errorMsg={
                      form.formState.errors[attributes.name]?.message as string
                      || (node.messages?.[0]?.type === "error" && node.messages?.[0]?.text)
                      || ""
                    }
                    readOnly={false}
                    inputProps={{
                      type: attributes.type as UiNodeInputAttributesTypeEnum,
                      placeholder: node.meta.label?.text || attributes.name,
                      ...form.register(attributes.name as any, {
                        required: {
                          value: attributes.required || false,
                          message: "Ne peut pas être vide",
                        },
                        pattern: attributes.pattern ? new RegExp(attributes.pattern) : undefined,
                      }),
                    }}
                    containerProps={{
                      className: 'w-full',
                    }}
                  />
                )
              case 'hidden':
                return (
                  <input
                    key={attributes.name}
                    type="hidden"
                    {...form.register(attributes.name as any, {
                      value: attributes.value,
                    })}
                  />
                )
            }

          })}

          <div className={styles.actions}>
            {oryLoginRegisterFlow.ui.nodes.map((node) => {

              if (node.attributes.node_type !== "input") {
                return null;
              }
              const attributes = node.attributes as UiNodeInputAttributes;

              if (attributes.type !== 'submit') {
                return null;
              }

              return (
                <Button
                  key={attributes.name}
                  label={loadingLoginOrRegister ? (
                    <Oval
                      height={20}
                      width={20}
                      color="white"
                      secondaryColor="white"
                      strokeWidth={2}
                      strokeWidthSecondary={2}
                    />
                  ) : (node.meta.label?.text || attributes.name)}
                  type="primary"

                  containerProps={{
                    className: styles.actionButton,
                    disabled: loadingLoginOrRegister || form.formState.isLoading || Object.keys(form.formState.errors).length > 0,
                    type: "submit",
                  }}
                />
              )
            })}

            {!isRegister && (
              <Button
                label="Vous n'avez pas de compte ?"
                onClick={() => navigate("../register")}
                type="secondary"
                containerProps={{
                  className: styles.actionButton,
                }}
              />
            )}

            {isRegister && (
              <Button
                label="Vous avez un compte"
                onClick={() => navigate("../login")}
                type="secondary"
                containerProps={{
                  className: styles.actionButton,
                }}
              />
            )}

          </div>

        </form>
        )}



      </div>

      <div className={styles.footerContainer}>
        <FooterLinks
          containerProps={{
            className: styles.footer,
          }}
        />
      </div>

    </PageContainer>
  )
}

