import styles from './Warranties.module.scss';
import PageContainer from 'src/layout/Page/PageContainer/PageContainer';
import HeaderSelfCare from 'src/layout/Header/Header.selfcare';

import Button from '@components/Button/Button';
import { useNavigate } from 'react-router-dom';
import WarrantiesList from '@components/WarrantiesList/WarrantiesList';
import { useCurrentMission } from 'src/Stores/CurrentMission';

export default function WarrantiesSelfCare() {

  const navigate = useNavigate();
  const { mission } = useCurrentMission();

  return (
    <PageContainer
      containerProps={{
        className: [styles.container, 'selfcare'].join(" "),
      }}
    >

      <HeaderSelfCare />

      <div className={styles.content}>

        <div className={styles.title}>
          Vos offres de transport disponibles
        </div>

        <p className={styles.subtitle}>
          Certaines offres peuvent entrainer un reste à charge selon vos conditions contractuelles.
        </p>

        <div className={styles.warranties}>
          <WarrantiesList
            layout={"selfcare"}
            containerProps={{
              className: styles.warrantiesListContainer,
            }}
            warranties={mission?.guaranteeDetails?.services || []}
          />
        </div>

        <div className={styles.actions}>
          <Button
            label="J'ai compris"
            onClick={() => navigate(-1)}
            type="primary"
            containerProps={{
              className: styles.actionButton,
            }}
          />
        </div>

      </div>

    </PageContainer>
  )
}