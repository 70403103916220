import React from 'react';
import styles from './FilterByTransportType.module.scss';
import { TypeSolutionsByTransportType } from '../useItinerarySolutions';
import {Arrival, Car, Recommend, Taxi, Train, Walk} from '@assets/icons/icons';
import formatDuration from 'src/helpers/duration';
import { TransportTypes } from 'src/Stores/CurrentMissionTypes/Types';

export default function FilterByTransportType({

  mainTransportTypeFilter,
  setMainTransportTypeFilter,
  solutionsByTransportTypes,
  containerProps: { className, ...containerProps } = {},
}: {

  mainTransportTypeFilter: TransportTypes | null;
  setMainTransportTypeFilter: (t: TransportTypes | null) => void;
  solutionsByTransportTypes: TypeSolutionsByTransportType;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}) {

  const transportTypeToIcon: {[key in TransportTypes]: React.ReactNode} = {
    walk: <Walk />,
    taxi: <Taxi />,
    train: <Train />,
    vrental: <Car />,
    arrival: <Arrival />,
  }

  return (
    <div className={[styles.container, className].join(" ")} {...containerProps}>
      <div
        onClick={() => {
          setMainTransportTypeFilter(null);
        }}
        className={[styles.filterItemContainer, mainTransportTypeFilter === null ? styles.selected : ""].join(" ")}
      >
        <Recommend />
        <span className={styles.duration}>
          Reco
        </span>
      </div>

      {(Object.keys(solutionsByTransportTypes) as TransportTypes[]).map((solutionsByTransportTypeKey) => {
        const solutionsByTransportType = solutionsByTransportTypes[solutionsByTransportTypeKey];
        if (solutionsByTransportType.countSolutions === 0) {
          return null;
        }

        return (
          <div
            key={solutionsByTransportTypeKey}
            onClick={() => {
              setMainTransportTypeFilter(solutionsByTransportTypeKey);
            }}
            className={[styles.filterItemContainer, solutionsByTransportTypeKey === mainTransportTypeFilter ? styles.selected : ""].join(" ")}
          >
            {transportTypeToIcon[solutionsByTransportTypeKey]}
            <span className={styles.duration}>
              {formatDuration({
                value: solutionsByTransportType.minimumDuration,
                unit: 'minutes'
              })} +
            </span>
          </div>
        )
      })}
    </div>
  )
}