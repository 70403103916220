import PageContainer from 'src/layout/Page/PageContainer/PageContainer';
import styles from './CreateCasePage.module.scss';
import Header from 'src/layout/Header/Header';
import InputText from '@components/Inputs/InputText/InputText';
import Button from '@components/Button/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import stylesForm from '../_Components/Form.module.scss';
import { Controller, useForm } from "react-hook-form";
import { authenticatedApi } from 'src/Api/api';
import PhoneNumberInput from '@components/PhoneNumberInput/PhoneNumberInput';
import usePhoneNumberFormat from '@components/PhoneNumberInput/usePhoneNumberFormat';


type FormData = {
  fileId: string;
  clientLastname: string;
  clientFirstname: string;
  clientPhone: string;
  licensePlate: string;
};

export default function CreateCasePage({
  containerProps: { className, ...containerProps } = {},
}: {
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}) {

  const { state } = useLocation();
  const navigate = useNavigate();

  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: {
      errors,
    },

  } = useForm<FormData>({
    mode: "onBlur",
    defaultValues: {
      fileId: state?.caseId || "",
      clientLastname: "",
      clientFirstname: "",
      clientPhone: state?.phoneNumber || "fr +33",
      licensePlate: state?.licensePlate || "",
    }
  });

  const phoneNumber = watch("clientPhone");
  const { numberOfDigits: phoneNumberCountDigits, regexp: phoneNumberRegexp } = usePhoneNumberFormat(phoneNumber);

  const onSubmit = async (data: FormData) => {

    if (!data.fileId) {
      return false;
    }

    const submitAction: {
      href: string;
      method: string;
    } = {
      href: `/api/v1/cases/${data.fileId}/init`,
      method: "POST",
    }

    const response = await authenticatedApi.request({
      url: submitAction.href,
      method: submitAction.method,
      data: {
        clientFirstname: data.clientFirstname,
        clientLastname: data.clientLastname,
        clientPhone: data.clientPhone,
        licensePlate: data.licensePlate,
      }
    });

    if (response.status === 200) {
      navigate("/cases/" + response.data.case.id, {
        state: {
          getCaseRequestResponse: response.data
        }
      });
    }

    return false;
  }

  const firstNameLastNameRegex = new RegExp("^[A-Za-zÀ-ÖØ-öø-ÿ- ]+$");

  return (
    <PageContainer
      containerProps={{
        ...containerProps,
        className: [styles.container, className].join(" "),
      }}
    >

      <Header
        type='user'
      />

      <div className={styles.content}>
        <div className={styles.formContainer} style={{ minWidth: "500px" }}>
          <div className={styles.formTitle}>Créer un dossier</div>

          <div className={[styles.formDescription, styles.formDescWarning].join(" ")}>

          </div>

          <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>

            <div className={`${stylesForm.formRow} ${styles.formRow}`}>
              <InputText
                testid='input-file-id'
                label="Numéro de dossier"
                errorMsg={errors.fileId?.message}
                inputProps={{
                  type: "text",
                  placeholder: "Entrez le numéro de dossier",
                  ...register("fileId", {
                    required: "Le numéro de dossier est obligatoire",
                    maxLength: {
                      value: 11,
                      message: "Le numéro de dossier doit contenir 11 caractères"
                    },
                    minLength: {
                      value: 11,
                      message: "Le numéro de dossier doit contenir 11 caractères"
                    }
                  })
                }}
                containerProps={{
                  className: "w-full",
                }}
              />
            </div>

            <div className={`${stylesForm.formRow} ${styles.formRow}`}>
              <InputText
                testid='input-lastname'
                label="Nom"
                errorMsg={errors.clientLastname?.message}
                inputProps={{
                  type: "text",
                  placeholder: "Entrez le nom du bénéficiaire",
                  ...register("clientLastname", {
                    required: "Le nom est obligatoire",
                    // no numbers
                    pattern: {
                      value: firstNameLastNameRegex,
                      message: "Le nom ne doit pas contenir de chiffres"
                    },
                    onChange: (e) => {
                      if (!firstNameLastNameRegex.test(e.target.value)) {
                        e.target.value = e.target.value.slice(0, -1);
                      }
                    }
                  })
                }}
                containerProps={{
                  className: "w-full",
                }}
              />
            </div>

            <div className={`${stylesForm.formRow} ${styles.formRow}`}>
              <InputText
                testid='input-firstname'
                label="Prénom"
                errorMsg={errors.clientFirstname?.message}
                inputProps={{
                  type: "text",
                  placeholder: "Entrez le prénom du bénéficiaire",
                  ...register("clientFirstname", {
                    required: "Le prénom est obligatoire",
                    pattern: {
                      value: firstNameLastNameRegex,
                      message: "Le prénom ne doit pas contenir de chiffres"
                    },
                    onChange: (e) => {
                      if (!firstNameLastNameRegex.test(e.target.value)) {
                        e.target.value = e.target.value.slice(0, -1);
                      }
                    }
                  })
                }}
                containerProps={{
                  className: "w-full",
                }}
              />
            </div>

              <Controller
                name="clientPhone"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "Le numéro de téléphone est obligatoire"
                  },
                  pattern: (phoneNumberRegexp) ? {
                    value: new RegExp(phoneNumberRegexp),
                    message: phoneNumberCountDigits ? `Le numéro de téléphone doit contenir ${phoneNumberCountDigits} chiffres` : 'Vous devez choisir un pays'
                  } : undefined,
                }}
                render={({ field }) => (
                  <>
                    <div className={`${stylesForm.formRow} ${styles.formRow}`}>
                      <PhoneNumberInput
                        testid='input-phone-number'
                        label="Numéro de téléphone"
                        errorMsg={errors.clientPhone?.message}
                        containerProps={{
                          className: "w-full",
                        }}
                        value={field.value}
                        onChange={(value) => {
                          field.onChange(value);
                        }}
                      />
                    </div>
                  </>
                )}
              />

            <div className={`${stylesForm.formRow} ${styles.formRow}`}>
              <InputText
                testid='input-license-plate'
                label={(
                  <div>
                    Plaque d’immatriculation
                  </div>
                )}
                errorMsg={errors.licensePlate?.message}
                inputProps={{
                  type: "text",
                  placeholder: "Entrez la plaque d’immatriculation",
                  ...register("licensePlate", {
                    required: "La plaque d’immatriculation est obligatoire"
                  })
                }}
                containerProps={{
                  className: "w-full",
                }}
              />
            </div>

            <Button
              label="Valider"
              testid='button-create-case'
              containerProps={{
                className: styles.button,
                type: "submit",
              }}
            />
            <Button
              label="Annuler"
              type='secondary'
              testid='button-cancel-create-case'
              containerProps={{
                className: `${styles.button} ${styles.buttonCancel}`,
                type: "button",
              }}
              onClick={() => {
                navigate("/");
              }}
            />
          </form>

        </div>
      </div>

    </PageContainer>
  )
}