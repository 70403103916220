import React, { useState } from 'react';
import styles from './VRentalSection.module.scss';
import { VRentalRecapType } from 'src/Stores/CurrentMissionTypes/MissionContextType';
import { format } from 'date-fns';
import ModalExtendRental from './ModalExtendRental/ModalExtendRental';
import AlertBox from '@components/AlertBox/AlertBox';
import { isSelfCare } from 'src/Stores/AppContext';
import Tag from '@components/Tag/Tag';
import { statusLabels } from 'src/helpers/statusLabels';
import { Alert } from 'src/models/Alert';

export default function VRentalSection({
  rental,
  alert,
  containerProps: { className, ...containerProps } = {},
}: {
  rental: VRentalRecapType;
  alert?: Alert;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}) {

  const [modalOpened, setModalOpened] = useState(false);

  const dateStart = new Date(rental.startDateTime);
  const dateEnd = new Date(rental.endDateTime);

  return (
    <div className={[styles.container, className].join(" ")} {...containerProps}>

      {modalOpened && (
        <ModalExtendRental
          rental={rental}
          onRequestClose={() => setModalOpened(false)}
        />
      )}

      <div className={styles.title}>
        Location de véhicule
      </div>
      {rental.reservationNumber && (
      <div className={styles.reservationNumber}>
        <b>N° Résacar : </b>{rental.reservationNumber}
      </div>
      )}
      <div className={styles.period}>
        {rental.numberOfDays} jour{rental.numberOfDays > 1 ? 's' : ''} du {format(dateStart, 'dd/MM HH:mm')} au {format(dateEnd, 'dd/MM HH:mm')}
      </div>
      {!rental.arrivalAgency && (
      <div className={styles.sameAgency}>
        Même agence de départ et d’arrivée
      </div>
      )}

      {!!alert && (<AlertBox
        type={alert.severity}
        text={alert.texts}
        displayIcon
        containerProps={{
          style: {
            marginTop: '20px',
          }
        }}
      />)}

      <div className={styles.vehiculeWithPriceContainer}>
        <div className={styles.vehicule}>
          <div className={styles.vehiculeImage}>
            {rental.vehicle.imgURL && (
              <img src={rental.vehicle.imgURL} alt="" width={100} />
            )}
          </div>
          <div className={styles.vehiculeInfos}>
            <div className={styles.vehiculeName}>
              {rental.vehicle.label}
            </div>
            <div className={styles.vehiculeOptions}>
              {rental.vehicle.descriptions && rental.vehicle.descriptions.map((description, i) => (
                <div key={i} className={styles.vehiculeOption}>
                  {description}
                </div>
              ))}
            </div>
          </div>
          {rental.statusWithLabel?.isDisplayed && (
            <Tag
              label={rental.statusWithLabel.label}
              color={statusLabels[rental.statusWithLabel.id].color}
              containerProps={{ className: "ml-8" }}
            />
          )}
        </div>
        <div className={styles.vehiculePriceAndActionContainer}>
          <div className={styles.vehiculePrice}>
            {rental.price.formatted} {rental.price.currency.symbol}
          </div>

          {rental.extendAction && (
            <div className={styles.modifyActionContainer}>
              <button
                className={styles.extendButton}
                onClick={() => {
                  setModalOpened(true);
                }}
              >
                Modifier
              </button>
            </div>
          )}

        </div>
      </div>


      {[rental.departureAgency, rental.arrivalAgency].map((agency) => agency ?
        <div className={styles.agencyInfosWithLogo} key={agency.label}>
            <div className={styles.agencyLogo}>
                {agency.imgURL && (
                    <img src={agency.imgURL} alt="" width={100}/>
                )}
            </div>
            <div className={styles.agencyInfos}>
                <div className={styles.agencyName}>
                    {agency.label}
                </div>
                <div className={styles.agencyDescriptions}>
                    {agency.descriptions && agency.descriptions.map((str, i) => (
                        <div key={i} className={styles.agencyDescriptionLine}>
                            {str}
                        </div>
                    ))}
                    {agency.openingHours && agency.openingHours.map((str, i) => (
                        <div key={i} className={styles.agencyDescriptionLine}>
                            {str}
                        </div>
                    ))}
                </div>
            </div>
        </div> : null
      )}

      {!isSelfCare() && rental.onCallPhone && (
        <AlertBox
          containerProps={{
            className: "mt-5",
          }}
          type="warning"
          displayIcon
          text={`Pour confirmer une location pendant une astreinte, veuillez appeler au préalable le numéro : ${rental.onCallPhone}`}
        />
      )}
    </div>
  )
}