import styles from './ItinerarySolutions.module.scss';
import layoutStyles from '../../CurrentMission.module.scss';
import Button from '@components/Button/Button';

import Itinerary from '@components/Itinerary/Itinerary';
import Header from 'src/layout/Header/Header';
import PageContainer from 'src/layout/Page/PageContainer/PageContainer';
import ItineraryMap from './ItineraryMap/ItineraryMap';
import { MapWrapper } from '@components/GoogleMap/Map';
import CurrentMissionVerticalSteps from '../../CurrentMissionVerticalSteps';
import FilterButton from '@components/FilterButton/FilterButton';
import FilterModal from 'src/Pages/_Components/Filter/FilterV3/FilterModal/FilterModal';
import useItinerarySolutions from './useItinerarySolutions';
import { Oval } from 'react-loader-spinner';
import AlertBox from '@components/AlertBox/AlertBox';

export default function ItinerarySolutions() {

  const itinerarySolutions = useItinerarySolutions();

  if (!itinerarySolutions) {
    return null;
  }

  const {
    alerts,
    mission,
    extendSearchCallAction,
    extendActionLoading,
    title,
    titleClass,
    solutions,
    solutionsCount,
    points,
    selected,
    loading,
    stepIsReadOnly,
    isFilteredByPoi,
    remainingEnvelopeAmount,
    getRemainingEnvelopeText,
    onSelectItinerary,
    onSubmit,
    displayOnlyTop3,
    setDisplayOnlyTop3,
    solutionsToDisplay,
    idsFiltered,
    appliedFilterValues,
    countNbFiltersApplied,
    resetFilters,
    onClickButtonFilter,
    modalProps,
    setSolutionIdsActivated,
  } = itinerarySolutions;


  return (
    <MapWrapper>

      <FilterModal
        {...modalProps}
      />

      <PageContainer
        containerProps={{
          className: [layoutStyles.container].join(" "),
        }}
      >

        <Header
          type='mission'
        />

        <div data-testid="step-solutions" className={layoutStyles.content}>

          <CurrentMissionVerticalSteps
            displayBackButton
          />

          <div className={styles.mapContainer}>
            <div className={styles.sectionHeader}>
              <div className={styles.sectionHeaderTitle}>
                {points.length} point{points.length > 1 ? 's' : ''} trouvé{points.length > 1 ? 's' : ''} avec {solutionsCount} solution{solutionsCount > 1 ? 's' : ''}
              </div>
            </div>

            <ItineraryMap
              solutions={solutions}
              onPointSelected={(point) => {
                if (point) {
                  setSolutionIdsActivated(point.solutionsIds);
                } else {
                  setSolutionIdsActivated([]);
                }
              }}
            />

            {extendSearchCallAction && (
            <div className={`flex flex-row items-center ${styles.expandSearchButtonContainer}`}>
              <Button
                type='secondary'
                label="Etendre la recherche"
                containerProps={{
                  className: styles.expandSearchButton,
                  disabled: extendActionLoading || stepIsReadOnly,
                }}
                onClick={extendSearchCallAction}
              />
              {extendActionLoading && (
                <Oval
                  height={40}
                  width={40}
                  strokeWidth={5}
                  strokeWidthSecondary={5}
                />
              )}
            </div>
            )}

          </div>
          <div className={styles.resultsContainer}>
            <div className={styles.sectionHeader}>
              <div className={`${styles.sectionHeaderTitle} ${titleClass}`}>
                {title}
              </div>
              <div className='flex flex-row items-center'>
                <FilterButton
                  onClick={onClickButtonFilter}
                  countNbFiltersApplied={countNbFiltersApplied}
                />
              </div>

            </div>

            {alerts.map((alert, i) => (
              <AlertBox
                key={i}
                displayIcon
                type={alert.severity}
                containerProps={{
                  style: {
                    marginBottom: '24px'
                  }
                }}
                text={alert.texts}
              />
            ))}

            <div className={styles.results}>

              {solutionsToDisplay.length === 0 && (
                <>
                  <div className={styles.noResults}>
                    Aucun résultat ne correspond à votre recherche actuelle. Pour obtenir plus de résultats, veuillez élargir vos critères de recherche.
                  </div>
                  <Button
                    type="secondary"
                    label="Réinitialiser les filtres"
                    onClick={() => {
                      resetFilters();
                    }}
                  />
                </>
              )}

              {solutionsToDisplay.map((solution, i) => {

                let title = `Via ${solution.mainOrigin.name}`;
                let title2: string | undefined =  undefined;
                if (solution.mainOrigin?.city) {
                  title = `Via ${solution.mainOrigin.name}`;
                  title2 = `${solution.mainOrigin.city ? " " + solution.mainOrigin.city : ""}`;
                }
                return (
                  idsFiltered[solution.id] ? (
                  <Itinerary
                    key={solution.id}
                    readOnly={stepIsReadOnly}
                    title={isFilteredByPoi && solution.mainDestination ? `Vers ${solution.mainDestination.name}` : title}
                    title2={isFilteredByPoi ? undefined : title2}
                    departureDateTimeIso={solution.departureDatetime}
                    arrivalDateTimeIso={solution.arrivalDatetime}
                    durationInMinutes={solution.durationInMinutes}
                    price={{
                      amount: solution.bestPrice.amount,
                      formatted: solution.bestPrice.formatted,
                      currency: {
                        symbol: solution.bestPrice.currency.symbol,
                        code: solution.bestPrice.currency.code,
                      },
                    }}
                    selected={selected === i}
                    onSelect={onSelectItinerary}
                    onSelectParams={{
                      index: i,
                    }}
                    containerProps={{
                      className: styles.itinerary,
                    }}
                    mainTransportType={solution.mainTransportType.id}
                    sections={solution.sections}
                    co2Emission={solution.kgCO2e ? {
                      value: solution.kgCO2e,
                      level: solution.ecologyRating
                    } : undefined}
                    remainingCosts={solution.remainingCosts}
                    transportations={solution.sections.map((section) => section.type)}
                    tags={solution.tags}
                    showRemainingCosts={appliedFilterValues.hasRemainingCosts as boolean}
                  />
                  ): null
                );
              })}

            </div>

            {displayOnlyTop3 && solutions.length > 3 && (
              <div onClick={() => {
                setDisplayOnlyTop3(false);
              }} className={styles.displayMoreButton}>Voir toutes les offres disponibles</div>
            )}

          </div>

        </div>

        <div className={layoutStyles.footer}>
          <div />
          <div className={styles.footerBtnContainer}>
            {mission.guaranteeDetails?.isEnvelope && remainingEnvelopeAmount &&(

              <div className={`${styles.envelope} ${remainingEnvelopeAmount.amount === 0 ? styles.isNegative : ''}`}>
                {getRemainingEnvelopeText()}
              </div>
            )}
            <Button
              testid='button-submit'
              containerProps={{
                disabled: selected === null || loading || stepIsReadOnly,
              }}
              label={(extendActionLoading || loading) ? "Chargement..." : "Valider"}
              onClick={() => {
                onSubmit();
              }}
            />
          </div>
        </div>

      </PageContainer>
    </MapWrapper>
  )
}