import styles from './Receipt.module.scss';
import PageContainer from 'src/layout/Page/PageContainer/PageContainer';
import HeaderSelfCare from 'src/layout/Header/Header.selfcare';
import Button from '@components/Button/Button';
import AddPhoto from './Components/AddPhoto/AddPhoto';
import { useState } from 'react';
import Select from '@components/Select/Select';
import InputText from '@components/Inputs/InputText/InputText';
import { NumericFormat } from 'react-number-format';
import { RefundFlowType } from 'src/Stores/CurrentMissionTypes/MissionContextType';
import { ScreenType } from '../../ScreensTypes';
import { useCurrentMission } from 'src/Stores/CurrentMission';
import { Oval } from 'react-loader-spinner';
import { Controller, useForm } from 'react-hook-form';
import * as EmailValidator from 'email-validator';


type FormData = {
  email: string;
}

export default function AddReceiptStepReceiptSelfCare({
  refundFlow,
  goBack,
}: {
  refundFlow: RefundFlowType;
  navigate: (screen: ScreenType) => void;
  goBack: () => void;
}) {

  const {
    callAction
  } = useCurrentMission();
  const [loading, setLoading] = useState(false);
  const [photo, setPhoto] = useState<File | null>(null);
  const [justificatifTypeIndex, setJustificatifTypeIndex] = useState<number | null>(null);
  const [amount, setAmount] = useState<number>(0);

  const {
    control,
    handleSubmit,
    formState: {
      errors,
      isValid
    },

  } = useForm<FormData>({
    defaultValues: {
      email: refundFlow.email || "",
    },
    mode: "onChange"
  });

  const canSubmit = () => {
    if (!isValid) {
      return false;
    }
    return photo !== null && justificatifTypeIndex !== null && amount > 0;
  }

  const submit = (formData: FormData) => {
    if (photo === null || justificatifTypeIndex === null || amount <= 0) {
      return;
    }

    const action = refundFlow?.actions?.uploadReceiptAction;
    if (!action) {
      return false;
    }

    const data = new FormData();
    data.append("uploadFile", photo);
    data.append("amount", amount.toString());
    data.append("benefit", (refundFlow.benefits || [])?.[justificatifTypeIndex]?.value);

    if (formData.email) {
      data.append("email", formData.email);
    }

    setLoading(true);
    callAction(action, data, {
      axiosRequestConfig: {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
    }).finally(() => {
      setLoading(false);
    });
  }

  return (
    <PageContainer
      containerProps={{
        className: [styles.container, 'selfcare'].join(" "),
      }}
    >

      <HeaderSelfCare
        title="Ajout d'un justificatif"
        displayBackButton
        onBackButtonClick={() => goBack()}
      />

      <form className={styles.content} onSubmit={handleSubmit(submit)}>

        <AddPhoto
          photo={photo}
          setPhoto={setPhoto}
          disabled={loading}
        />

        <Select
          label="Prestation"
          optionsV2={(refundFlow.benefits || []).map((benefit) => ({  value: benefit.value, label: benefit.name }))}
          selectedIndex={justificatifTypeIndex}
          onChangeIndex={setJustificatifTypeIndex}
          disabled={loading}
          containerProps={{
            className: 'w-full',
            style: { marginTop: "40px" },
          }}
        />

        <div className={`flex flex-row justify-between align-center w-full`} style={{ marginTop: "16px" }}>

          <InputText
            disabled={loading}
            label={"Montant TTC"}
            errorMsg={""}
            readOnly={loading}
            containerProps={{
              className: 'w-full',
            }}

            inputComponent={(
              <NumericFormat
                allowedDecimalSeparators={[".", ","]}
                decimalScale={2}
                thousandSeparator={" "}
                decimalSeparator=','
                value={amount / 100}
                onChange={(e) => {
                  const amountCentimes = Math.floor(parseFloat(e.target.value.replace(/ /g, "").replace(/,/g, ".")) * 100);
                  if (isNaN(amountCentimes) || amountCentimes < 0) {
                    setAmount(0);
                    return;
                  }
                  setAmount(amountCentimes);
                }}
              />
            )}
          />

          <div className="flex flex-col justify-center">€</div>

        </div>

        <div className={`flex flex-row justify-between align-center w-full`} style={{ marginTop: "16px" }}>
          <Controller
            control={control}
            name="email"
            rules={{
              required: {
                value: true,
                message: "l'email est obligatoire"
              },
              validate: {
                value: (value) => {
                  if (!EmailValidator.validate(value)) {
                    return "l'email n'est pas valide";
                  }
                },
              }
            }}
            render={({ field }) => (
              <InputText
                label={"Email"}
                errorMsg={errors.email?.message}
                readOnly={loading}
                disabled={loading}
                inputProps={field}
                containerProps={{
                  className: 'w-full',
                }}
              />
            )}
          />
        </div>

        <div className={styles.actions}>
          <Button
            label={loading ? (
              <Oval color="#fff" height={20} width={20} />
            ) : "Soumettre ma demande"}
            type="primary"
            containerProps={{
              type: "submit",
              className: styles.actionButton,
              disabled: !canSubmit() || loading,
            }}
          />
        </div>

      </form>

    </PageContainer>
  )
}