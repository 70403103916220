import styles from './AddReceipt.module.scss';
import PageContainer from 'src/layout/Page/PageContainer/PageContainer';
import HeaderSelfCare from 'src/layout/Header/Header.selfcare';
import Button from '@components/Button/Button';
import { useCurrentMission } from 'src/Stores/CurrentMission';
import receipt_image from './images/transport.svg';
import { ScreenType } from './ScreensTypes';

export default function AddReceiptSelfCare({
  navigate
}: {
  navigate: (screen: ScreenType) => void;
}) {

  const { mission } = useCurrentMission();

  if (!mission) {
    throw new Error("Mission not found")
  }

  return (
    <PageContainer
      containerProps={{
        className: [styles.container, 'selfcare'].join(" "),
      }}
    >

      <HeaderSelfCare
      />

      <div className={styles.content}>

        <div className={styles.title}>
          Bienvenue {`${mission?.clientFirstname} ${mission?.clientLastname}`}
        </div>

        <p>
          Ajoutez vos justificatifs de paiement en toute simplicité pendant l'immobilisation de votre véhicule.
        </p>

        <div className={styles.imgInfoContainer}>
          <img src={receipt_image} alt="Illustration add receipt" />
        </div>

        <div className={styles.actions}>
          <Button
            label="Demande de remboursement"
            onClick={() => navigate("receipt")}
            type="primary"
            containerProps={{
              className: styles.actionButton,
            }}
          />
        </div>

      </div>

    </PageContainer>
  )
}