import { Outlet } from "react-router-dom";
import styles from './AuthPageContainer.module.scss';
import PageContainer from "src/layout/Page/PageContainer/PageContainer";
import Header from "src/layout/Header/Header";

export default function AuthPageContainer() {
  return (
    <PageContainer
      containerProps={{
        className: [styles.container].join(" "),
      }}
    >

      <Header
        type='logged-out'
      />

      <div id={styles.loginContent} className={styles.content}>

        <Outlet />
      </div>

    </PageContainer>
  );
}
