import styles from './Home.module.scss';
import PageContainer from 'src/layout/Page/PageContainer/PageContainer';
import HeaderSelfCare from 'src/layout/Header/Header.selfcare';
import transport_img from './images/transport.svg';
import Button from '@components/Button/Button';
import { useNavigate } from 'react-router-dom';
import FooterLinks from 'src/layout/SelfCare/Footer/FooterLinks';
import { useAuthStore } from 'src/Stores/AuthStore';
import { useEffect, useState } from 'react';
import { getAuthenticatedApiInstance } from 'src/Api/api';
import { MissionContextType } from 'src/Stores/CurrentMissionTypes/MissionContextType';
import { MissionType } from 'src/models/Mission';

export default function HomeSelfCare() {

  const navigate = useNavigate();

  const {
    isAuthenticatedSelfCareKratos
  } = useAuthStore();

  const [lastMission, setLastMission] = useState<{
    context: MissionContextType | null;
    mission: MissionType | null;
  } | null>(null);

  useEffect(() => {
    if (!isAuthenticatedSelfCareKratos) {
      return;
    }
    getAuthenticatedApiInstance({
      errorHandlerEnabled: false
    }).get("/missions").then((response) => {
      console.log(response.data);
      if (!response.data?.lastMission?.actions?.view?.href) {
        throw new Error("No view action found");
      }
      getAuthenticatedApiInstance({
        prefixIfSelfCare: false
      }).get(response.data.lastMission.actions.view.href).then((response) => {
        console.log(response.data);
        setLastMission((response.data as {
          context: MissionContextType | null;
          mission: MissionType | null;
        }) || null);
      });

    }).catch((error) => {
      console.error(error);
      setLastMission(null);
    });
  }, [isAuthenticatedSelfCareKratos]);

  console.log("missions", lastMission);

  return (
    <PageContainer
      containerProps={{
        className: [styles.container, 'selfcare'].join(" "),
      }}
    >

      <HeaderSelfCare
        displayMissionProgressBar
        displayWarrantiesButton={false}
        forceProgressBarPercent={10}
      />

      <div className={styles.content}>

        {isAuthenticatedSelfCareKratos && (
        <div className={styles.contentContainer}>

          <div className={styles.title}>
            Bienvenue
          </div>

          <p>
            Trouvez facilement des <b>solutions de mobilité</b> en toute simplicité pendant l'immobilisation de votre véhicule.
          </p>

          <div className={styles.imgInfoContainer}>
            <img src={transport_img} alt="Illustration" />
          </div>

          <div className={styles.actions}>
            <Button
              label="Voir ma réservation"
              onClick={() => {
                if (lastMission?.mission?.caseID && lastMission?.mission?.id) {
                  navigate(`cases/${lastMission.mission.caseID}/missions/${lastMission.mission.id}/mission`);
                }
              }}

              type="primary"
              containerProps={{
                className: styles.actionButton,
                disabled: lastMission === null,
              }}
            />

            <Button
              label="Réserver une nouvelle prestation"
              onClick={() => navigate("./new-mission")}
              type="secondary"
              containerProps={{
                className: styles.actionButton,
              }}
            />

          </div>

        </div>
        )}

        {!isAuthenticatedSelfCareKratos && (
        <div className={styles.contentContainer}>

          <div className={styles.title}>
            Bienvenue
          </div>

          <p>
            Gérer votre mobilité en toute simplicité pendant l'immobilisation de votre véhicule.
          </p>

          <p>
            Inscrivez vous afin de gérer votre mobilité.
          </p>

          <div className={styles.imgInfoContainer}>
            <img src={transport_img} alt="Illustration" />
          </div>

          <div className={styles.actions}>
            <Button
              label="Connexion"
              onClick={() => navigate("login")}
              type="primary"
              containerProps={{
                className: styles.actionButton,
              }}
            />

            <Button
              onClick={() => navigate("register")}
              type="secondary"
              label="Inscription"
              containerProps={{
                className: styles.actionButton,
              }}
            />

          </div>

        </div>
        )}

        <div className={styles.footerContainer}>
          <FooterLinks
            containerProps={{
              className: styles.footer,
            }}
          />
        </div>

      </div>



    </PageContainer>
  )
}