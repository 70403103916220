import VerticalSteps, { Step } from "@components/VerticalSteps/VerticalSteps";
import styles from './CurrentMission.module.scss';
import { useMediaQuery } from "usehooks-ts";
import { useCurrentMission } from "src/Stores/CurrentMission";
import { Chevron } from "@assets/icons/icons";
import { MissionStepKeys } from "src/Stores/CurrentMissionTypes/Types";

export default function CurrentMissionVerticalSteps({
  displayBackButton = false,
  containerProps: { className, ...containerProps } = {},
}: {
  displayBackButton?: boolean;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}) {


  const isLessThan1200 = useMediaQuery('(max-width: 1200px)');
  const { file, mission, missionContext, missionContextGoBack, canGoBack, missionContextGoToStep } = useCurrentMission();

  if (!file || !mission || !missionContext) {
    return null;
  }

  const onClickStep = (stepKey: MissionStepKeys) => {
    missionContextGoToStep(stepKey);
  }

  const currentStepIndex = missionContext.progressBar.currentIndex;
  const verticalSteps: Array<Step> = [];

  missionContext.progressBar.steps.forEach((step, i) => {
    verticalSteps.push({
      ...step,
      isEnable: i <= currentStepIndex,
      canClick: !!missionContext.steps[step.stepKey]?.goToStep
    })
  });

  return (
    <div className={[styles.verticalStepsContainer, displayBackButton ? styles.withBackButton : "", className].join(" ")} {...containerProps}>

      {displayBackButton && canGoBack() && (
      <div className={styles.backButtonContainer}>
        <div className={styles.btnBack} onClick={missionContextGoBack}>
          <Chevron /> <div className={styles.label}>retour</div>
        </div>
      </div>
      )}

      <VerticalSteps
        containerProps={{
          className: styles.verticalSteps,
        }}
        displayLabels={!isLessThan1200}
        steps={verticalSteps}
        onClickStep={onClickStep}
      />
    </div>
  )
}