
import { TrainProposalType } from "src/Stores/CurrentMissionTypes/Types";
import { FilterItem, FilterTypeMultiValues, FilterTypeSwitch } from "../types";
import useFilter, { UseFilterReturnType } from "../useFilter";
import { useMemo } from "react";


type ItemToFilter = {
  id: string;
  hasRemainingCosts: boolean;
  isDirect: boolean;
  flexibilityId: string;
};

type TrainOffersFiltersType = {
  flexibilities: FilterTypeMultiValues;
  hasRemainingCosts: FilterTypeSwitch;
  isDirect: FilterTypeSwitch;
}

function itemMatchesValuesForKey(item: FilterItem, filterItemKey: string, values: { [key: string]: boolean }) {

  const filterItemValues = item.dynamicFilters?.[filterItemKey]?.value as string[];
  if (!filterItemValues) {
    return false;
  }

  // If no values selected, we want to show all items
  if (Object.keys(values).filter((value) => values[value]).length === 0) {
    return true;
  }

  return filterItemValues.some((value: string) => {
    return values[value];
  });
}

const filtersConfig: TrainOffersFiltersType = {
  flexibilities: {
    type: 'multiselect',
    label: "Flexibilities",
    options: [],
    displayTags: true,
    getItemValues: (item: ItemToFilter) => {
      return {
        type: 'multi',
        value: [item.flexibilityId]
      };
    },
    itemMatchesValues: (item: FilterItem, values: { [key: string]: boolean }) => {
      return itemMatchesValuesForKey(item, 'flexibilities', values);
    }
  },

  hasRemainingCosts: {
    type: 'switch',
    label: "Afficher résultats avec reste à charge",
    labelTag: "Avec reste à charge",
    displayTags: true,
    getItemValues: (item) => {
      return {
        type: 'switch',
        value: !!item.hasRemainingCosts
      };
    },
    itemMatchesValues: (item: FilterItem, value: boolean) => {
      // If not checked, we don't want item that have remaining costs
      if (!value && item.dynamicFilters.hasRemainingCosts.value) {
        return false;
      }
      return true;
    },
    defaultSelectedValue: true,
  },

  isDirect: {
    type: 'switch',
    label: "Voir les trajets directs uniquement",
    labelTag: "Direct",
    displayTags: true,
    getItemValues: (item) => {
      return {
        type: 'switch',
        value: !!item.isDirect
      };
    },
    itemMatchesValues: (item: FilterItem, value: boolean) => {
      // If not checked, we don't want item that are not direct
      if (!value) {
        return true;
      }
      return item.dynamicFilters.isDirect.value === true;
    },
    defaultSelectedValue: false,
  }
}

function mapToBooleanTrue(values: string[]) {
  return values.reduce((acc: { [key: string]: boolean }, value) => {
    acc[value] = true;
    return acc;
  }, {});
}

export default function useFilterTrainOffers(
  trainProposals?: Array<TrainProposalType>,
  options?: {
    disabledFilters?: Array<keyof TrainOffersFiltersType>
  }
): UseFilterReturnType {

  const filters: TrainOffersFiltersType = useMemo(() => {

    const disabledFilters = options?.disabledFilters || [];

    const allFlexibilities = (trainProposals || []).map(p => p.cabinClasses.map(cabin => (cabin?.offers || []).flat()).flat()).flat().map(o => o.flexibilityId)
      .filter((value, index, self) => self.indexOf(value) === index).map((value) => ({
        label: value,
        value,
      }));

    const flexibilities: TrainOffersFiltersType['flexibilities'] = {
      ...filtersConfig.flexibilities,
      options: allFlexibilities,
      defaultSelectedValues: mapToBooleanTrue(allFlexibilities.map((option) => option.value)),
      disabled: allFlexibilities.length <= 1,
    };

    return {
      isDirect: {
        ...filtersConfig.isDirect,
        disabled: disabledFilters?.includes('isDirect') ||  filtersConfig.isDirect.disabled,
      }, // disabledFilters?.includes('energy') ||
      hasRemainingCosts: {
        ...filtersConfig.hasRemainingCosts,
        disabled: disabledFilters?.includes('hasRemainingCosts') || filtersConfig.hasRemainingCosts.disabled,
      },
      flexibilities: {
        ...flexibilities,
        disabled: disabledFilters?.includes('flexibilities') || flexibilities.disabled,
      }
    }
  }, [options?.disabledFilters, trainProposals]);

  const itemsToFilter = useMemo(() => {
    const items: Array<ItemToFilter> = [];
    trainProposals?.forEach((trainProposal) => {
      trainProposal.cabinClasses.forEach((cabinClass) => {
        cabinClass?.offers.forEach((offer) => {
          items.push({
            id: offer.id,
            hasRemainingCosts: !!offer.remainingCosts,
            isDirect: trainProposal.connectionsCount === 0,
            flexibilityId: offer.flexibilityId,
          });
        });
      });
    });

    return items;
  }, [trainProposals]);

  const useFilterValue = useFilter({
    items: itemsToFilter,
    filters,
  });

  return {
    ...useFilterValue,
    modalProps: {
      ...useFilterValue.modalProps,
      layout: [
        { type: 'filter', filterKey: 'hasRemainingCosts' },
        { type: 'filter', filterKey: 'isDirect' },
        { type: 'filter', filterKey: 'flexibilities' },
      ]
    }
  };
}

