import layoutStyles from '../../CurrentMission.module.scss';
import { useCurrentMission } from 'src/Stores/CurrentMission';
import WarrantiesListForm from 'src/Pages/_Components/WarrantiesList/WarrantiesList';
import ContractForm from 'src/Pages/_Components/ContractForm/ContractForm';
import SearchForm from 'src/Pages/_Components/SearchForm/SearchForm';
import styles from './SearchItinerary.module.scss';
import Header from 'src/layout/Header/Header';
import PageContainer from 'src/layout/Page/PageContainer/PageContainer';
import { Oval } from 'react-loader-spinner'
import { useState } from 'react';
import CurrentMissionVerticalSteps from '../../CurrentMissionVerticalSteps';
import FullScreenLoader from '@components/FullScreenLoader/FullScreenLoader';
import { isAppEnvProductionLike } from 'src/appEnv';
import SendLinkButton from 'src/Pages/_Components/SendSefCareLinks/SendLinkButton';
import useSendLinkButtons from 'src/Pages/_Components/SendSefCareLinks/useSendLinkButtons';

export default function SearchItinerary() {

  const { file, mission } = useCurrentMission();
  const {
    canSendRefundLink,
    canSendSelfCareLink
  } = useSendLinkButtons();

  const [editing, setEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);

  if (!file || !mission) {
    return null;
  }

  return (
    <PageContainer
      containerProps={{
        className: [layoutStyles.container].join(" "),
      }}
    >

      {loadingSearch && (
        <FullScreenLoader />
      )}

      <Header
        type='mission-search'
      />


      <div className={layoutStyles.content}>

        <CurrentMissionVerticalSteps />

        <div className={[styles.colFormContainer, styles.colFormLeft].join(" ")}>

          <div className={styles.formTitle}>
            Contrat d'assurance
          </div>

          <ContractForm
            testid='contract-form'
            editable
            onChangeEditState={({ isEditing, isLoading }) => {
              setEditing(isEditing);
              setLoading(isLoading);
            }}
            containerProps={{
              className: styles.contractForm,
            }}
          />

          <div className={styles.warrantiesListFormContainer}>
            {editing && (
              <div className={styles.overlay}>
                {loading && (
                <Oval
                  height={40}
                  width={40}
                  color="white"
                  secondaryColor="white"
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
                )}
              </div>
            )}
            <WarrantiesListForm
              containerProps={{
                className: styles.warrantiesListForm,
              }}
            />
          </div>


        </div>

        <div className={styles.colFormContainer}>

          {(canSendRefundLink || canSendSelfCareLink) && (
          <>
            <div className={styles.formTitle}>
              Rediriger vers le Selfcare
            </div>

            <div className={styles.sendLinkContainer}>

              {canSendSelfCareLink && (
                <SendLinkButton
                  type='search'
                  containerProps={{
                    className: styles.sendLinkButton,
                    disabled: isAppEnvProductionLike(),
                  }}
                />
              )}

              {canSendRefundLink && (
                <SendLinkButton
                  type='refund'
                  buttonType='secondary'
                  containerProps={{
                    className: styles.sendLinkButton,
                    disabled: isAppEnvProductionLike(),
                  }}
                />
              )}

            </div>
          </>
          )}


          <div className={styles.formTitle}>
            Recherche de mobilité
          </div>

          <div className={styles.searchFormContainer}>
            {editing && (
              <div className={styles.overlay}>
                {loading && (
                <Oval
                  height={40}
                  width={40}
                  color="white"
                  secondaryColor="white"
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
                )}
              </div>
            )}
            <SearchForm
              onChangeSearchLoading={(isLoading) => {
                setLoadingSearch(isLoading);
              }}
              containerProps={{
                className: [styles.searchForm].join(" "),
              }}
            />
          </div>

        </div>


      </div>

    </PageContainer>
  )
}