import React from 'react';
import styles from './HeaderChangeItinerarySelfCare.module.scss';
import BackBtn from 'src/layout/Header/BackBtn';
import { IconCalendar, IconFilter, IconJourney } from '@assets/icons/icons';
import { useCurrentMission } from 'src/Stores/CurrentMission';

export default function HeaderChangeItinerarySelfCare({
  date = "Mercredi 28 Juillet",
  origin,
  destination,
  onClickButtonFilter,
  containerProps: { className, ...containerProps } = {},
}: {
  date?: string;
  origin: string;
  destination: string;
  onClickButtonFilter?: () => any;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}) {

  const { missionContextGoBack } = useCurrentMission();

  return (
    <div className={[styles.container, "flex flex-row items-start", className].join(" ")} {...containerProps}>
      <div className="flex flex-col justify-start items-start">
        <BackBtn
          containerProps={{
            className: styles.backBtn
          }}
        />
      </div>
      <div className="flex-1 flex flex-col gap-2">
        <div className={styles.originDestContainer} onClick={missionContextGoBack}>
          <IconJourney />
          <div className={styles.originDest}>
            <div className={styles.origin}>{origin}</div>
            <div className={styles.dest}>{destination}</div>
          </div>
        </div>
        <div className={styles.dateAndFilterContainer}>
          <div className={styles.date} onClick={missionContextGoBack}>
            <IconCalendar />
            <div className={styles.dateLabel}>{date}</div>
          </div>
          <div
            className={styles.filterButton}
            onClick={onClickButtonFilter}
          >
            <IconFilter />
            <div className={styles.filterLabel}>Filtres</div>
          </div>
        </div>
      </div>
    </div>
  )
}