import React from 'react';
import styles from './RecapDateTime.module.scss';
import { format } from 'date-fns';
import formatDuration from 'src/helpers/duration';

export default function RecapDateTime({
  isTaxiOnly,
  validUntil,
  validUntilDate,
  optionIsToday,
  displayDuration = true,
  recapDate,
  durationMinutes,
  containerProps: { className, ...containerProps } = {},
}: {
  isTaxiOnly: boolean;
  validUntil: string;
  validUntilDate: Date;
  optionIsToday: boolean;
  displayDuration?: boolean;
  recapDate: Date;
  durationMinutes: number;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}) {

  return (
    <div className={[styles.recapDateTime, className].join(" ")} {...containerProps}>
      <div>
        <div className={styles.recapDate}>
          {format(recapDate, "PPPP à HH:mm")}
        </div>
        {displayDuration && (<div className={styles.recapTime}>
          Durée: {formatDuration({
            unit: 'minutes',
            value: durationMinutes,
          })}
        </div>)}
      </div>
      {!isTaxiOnly && validUntil && (
        <div className={styles.validUntil}>

          {optionIsToday ? (
            <p className={styles.validUntilText}>
              Option valable jusqu'à{" "}
              {validUntilDate.toLocaleTimeString("fr-FR", {
                hour: "2-digit",
                minute: "2-digit",
              })}
            </p>
          ) : (
            <p className={styles.validUntilText}>
              Option valable jusqu'au{" "}
              {validUntilDate.toLocaleDateString("fr-FR", {
                day: "2-digit",
                month: "short",
              })}{" "}
              {validUntilDate.toLocaleTimeString("fr-FR", {
                hour: "2-digit",
                minute: "2-digit",
              })}
            </p>
          )}

        </div>
      )}
    </div>
  )
}