import { useCurrentMission } from 'src/Stores/CurrentMission';
import SearchItinerary from './Pages/SearchItinerary/SearchItinerary';
import ItinerarySolutions from './Pages/ItinerarySolutions/ItinerarySolutions';
import ResultsTrainSelectionPage from './Pages/ResultsTrainSelection/ResultsTrainSelectionPage';
import PassengersPage from './Pages/TravelerForm/Passengers';
import ItineraryRecapPage from './Pages/ItineraryRecap/ItineraryRecapPage';
import ItineraryConfirmationPage from './Pages/ItineraryRecap/ItineraryConfirmationPage';
import ResultsVRentalSelection from './Pages/ResultsVRentalSelection/ResultsVRentalSelection';

export default function CurrentMission() {

  const { file, mission, missionContext } = useCurrentMission();

  if (!file || !mission || !missionContext) {
    return null;
  }

  switch (missionContext.currentStep) {
    case 'search':
      return <SearchItinerary />;
    case 'solutions':
      return (
        <ItinerarySolutions />
      );
    case 'searchTrain':
      return <ResultsTrainSelectionPage />;
    case 'searchVrental':
      return (
        <ResultsVRentalSelection />
      );
    case 'travelerForm':
      return (
        <PassengersPage />
      );
    case 'recap':
      return (
        <ItineraryRecapPage />
      );
    case 'confirmation':
    default:
      return (
        <ItineraryConfirmationPage />
      )
  }

}