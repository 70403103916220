import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

// Import your local translation files
import translationEN from './en/translation.json';
import translationFR from './fr/translation.json';

// Resources object containing your translations
const resources = {
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
};

i18n
  .use(LanguageDetector) // Use the language detector
  .use(initReactI18next) // Passes i18n down to react-i18next.
  .init({
    resources, // Provide local translations
    fallbackLng: 'en', // Fallback language
    debug: true,
    detection: {
      order: ['localStorage', 'querystring', 'cookie', 'navigator', 'htmlTag', 'path', 'subdomain'],
      lookupLocalStorage: 'i18nextLng',
      caches: ['localStorage'], // Cache the user language in localStorage
      excludeCacheFor: ['cimode'], // Do not cache these languages
    },
    interpolation: {
      escapeValue: false, // React already does escaping
    },
  });

// eslint-disable-next-line
// @ts-ignore
window.window_i18n = i18n;

export default i18n;
