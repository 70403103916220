import React, { useState } from 'react';
import styles from './VRentalSection.module.scss';
import { VRentalRecapType } from 'src/Stores/CurrentMissionTypes/MissionContextType';
import { format } from 'date-fns';
import ModalExtendRental from '../../../VRentalSection/ModalExtendRental/ModalExtendRental';
import AlertBox from '@components/AlertBox/AlertBox';
import { isSelfCare } from 'src/Stores/AppContext';
import ExpandableText from '@components/ExpandableText/ExpandableText';

export default function VRentalSection({
  rental,
  containerProps: { className, ...containerProps } = {},
}: {
  rental: VRentalRecapType;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}) {

  const [modalOpened, setModalOpened] = useState(false);

  const dateStart = new Date(rental.startDateTime);
  const dateEnd = new Date(rental.endDateTime);

  return (
    <div className={[styles.container, className].join(" ")} {...containerProps}>

      {modalOpened && (
        <ModalExtendRental
          rental={rental}
          onRequestClose={() => setModalOpened(false)}
        />
      )}

      <div className={styles.title}>
        Location {rental.numberOfDays} jour{rental.numberOfDays > 1 ? 's' : ''} du {format(dateStart, 'dd/MM HH:mm')} au {format(dateEnd, 'dd/MM HH:mm')}
      </div>
      {rental.reservationNumber && (
      <div className={styles.reservationNumber}>
        <b>N° Résacar : </b>{rental.reservationNumber}
      </div>
      )}


      <div className={styles.vehiculeWithImageContainer}>
        <div className={styles.vehiculeInfos}>
          <div className={styles.vehiculeName}>
            {rental.vehicle.selfCareLabel ?? rental.vehicle.label}
          </div>
          <div className={styles.vehiculeOptions}>
            {rental.vehicle.descriptions && rental.vehicle.descriptions.map((description, i) => (
              <div key={i} className={styles.vehiculeOption}>
                {description}
              </div>
            ))}
          </div>
        </div>

        <div className={styles.vehiculeImage}>
          {rental.vehicle.imgURL && (
            <img src={rental.vehicle.imgURL} alt="" width={100} />
          )}
        </div>
      </div>

      <div className={styles.agencyInfosSectionTitle}>Agence de retrait du véhicule</div>
      {[rental.departureAgency, rental.arrivalAgency].map((agency) => agency ?
        <div className={styles.agencyInfosWithLogo} key={agency.label}>
            <div className={styles.agencyInfos}>
                <div className={styles.agencyName}>
                    {agency.label}
                </div>
                <div className={styles.agencyDescriptions}>
                    {agency.descriptions && agency.descriptions.map((description, i) => (
                        <div key={i} className={styles.agencyDescriptionLine}>
                            {description}
                        </div>
                    ))}
                </div>
                <div className={styles.openingHours}>
                  <ExpandableText
                    title='Horaires d’ouverture'
                    allClickable
                    separator={false}
                    contentContainerProps={{
                      style: {
                        marginTop: '10px',
                      }
                    }}
                  >
                    {agency.openingHours && agency.openingHours.map((hour, i) => (
                        <div key={i} className={styles.openingHourLine}>
                            {hour}
                        </div>
                    ))}
                  </ExpandableText>
                </div>
            </div>
            <div className={styles.agencyLogo}>
                {agency.imgURL && (
                    <img src={agency.imgURL} alt="" width={100}/>
                )}
            </div>
        </div> : null
      )}

      {!rental.arrivalAgency && (
      <div className={styles.sameAgency}>
        Même agence de départ et d’arrivée
      </div>
      )}

      <div className={styles.CBAlert}>
        Lors de la récupération de votre véhicule, veuillez vous munir de votre carte bancaire pour le dépôt de garantie.
      </div>

      {!isSelfCare() && rental.onCallPhone && (
        <AlertBox
          containerProps={{
            className: "mt-5",
          }}
          type="warning"
          displayIcon
          text={`Pour confirmer une location pendant une astreinte, veuillez appeler au préalable le numéro : ${rental.onCallPhone}`}
        />
      )}
    </div>
  )
}