import { useCallback, useRef, useState } from 'react';
import styles from './TextEllipsisWithPopup.module.scss';
import PopupInfos from './PopupInfos';

export default function TextEllipsisWithPopup({
  children,
  containerProps: { className, ...containerProps } = {},
}: {
  children: string;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}) {

  const [containerAbsolutePosition, setContainerAbsolutePosition] = useState<DOMRect>();
  const [needTooltip, setNeedTooltip] = useState(false);
  const [displayTooltip, setDisplayTooltip] = useState(false);
  const container = useRef<HTMLDivElement>(null);

  const checkIfNeedTooltip = useCallback(() => {
    if (!container.current) {
      return;
    }

    // get position of container
    const boundingRect = container.current.getBoundingClientRect();
    setContainerAbsolutePosition(boundingRect);

    const containerElement = container.current;
    const containerWidth = containerElement.offsetWidth;
    const contentWidth = containerElement.scrollWidth;

    setNeedTooltip(contentWidth > containerWidth);
  }, [container]);

  return (
    <>
      <div
        ref={container}
        className={[styles.container, needTooltip ? styles.needTooltip : "", className].join(" ")}
        {...containerProps}
        onMouseEnter={() => {
          checkIfNeedTooltip();
          setDisplayTooltip(true);
        }}
        onTouchStart={() => {
          checkIfNeedTooltip();
          setDisplayTooltip(true);
        }}
        onTouchEnd={() => {
          setDisplayTooltip(false);
        }}
        onMouseLeave={() => {
          setDisplayTooltip(false);
        }}
      >
        {children}
      </div>

      <PopupInfos
        containerProps={{
          style: {
            display: needTooltip && displayTooltip ? "block" : "none",
            ...(containerAbsolutePosition ? {
              top: containerAbsolutePosition.bottom + 10,
              left: containerAbsolutePosition.left,
            } : {}),

          },
          onMouseEnter: () => {
            setDisplayTooltip(true);
          },
          onMouseLeave: () => {
            setDisplayTooltip(false);
          },
        }}

      >
        {children}
      </PopupInfos>
    </>
  )
}