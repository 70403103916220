import stylesForm from '../../Form.module.scss';
import styles from './styles.module.scss';
import Switch from '@components/Switch/Switch';
import Button from '@components/Button/Button';
import Modal from '@components/Modal/Modal';
import { useForm, Controller } from "react-hook-form";

type FormData = {
  has_manual_licence: boolean;
  has_bank_card: boolean;
  has_old_licence: boolean;
};

export default function ModalDriver({
  defaultValues,
  onChange,
  onRequestClose,
  containerProps: { className, ...containerProps } = {},
}: {
  defaultValues: FormData;
  onChange?: (driver: FormData) => any;
  onRequestClose?: () => any;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}) {
  const { control, handleSubmit } = useForm<FormData>({
    defaultValues
  });

  const onSubmit = (data: FormData) => {
    onChange && onChange(data);
    onRequestClose && onRequestClose();
  };

  return (
    <Modal
      onRequestClose={onRequestClose}
      title='Conducteur'
    >
      <div className={[stylesForm.form, styles.optionsModalContainer, className].join(" ")} {...containerProps}>

        <div className={stylesForm.formRow}>
          <div className={[stylesForm.formCol].join(" ")}>
            <span className={stylesForm.formLabelNormal}>
            Le conducteur a plus de 3 ans de permis
            </span>
          </div>
          <div className={[stylesForm.formCol].join(" ")}>

          <Controller
              name="has_old_licence"
              control={control}
              defaultValue={true}
              render={({ field }) => (
                <Switch
                  checked={field.value}
                  onChange={(checked) => field.onChange(checked)}
                />
              )}
            />

          </div>
        </div>


        <div className={stylesForm.formRow}>
          <div className={[stylesForm.formCol].join(" ")}>
            <span className={stylesForm.formLabelNormal}>
            Le conducteur détient un permis B boite manuelle
            </span>
          </div>
          <div className={[stylesForm.formCol].join(" ")}>
          <Controller
              name="has_manual_licence"
              control={control}
              defaultValue={true}
              render={({ field }) => (
                <Switch
                  checked={field.value}
                  onChange={(checked) => field.onChange(checked)}
                />
              )}
            />
          </div>
        </div>


        <div className={stylesForm.formRow}>
          <div className={[stylesForm.formCol].join(" ")}>
            <span className={stylesForm.formLabelNormal}>
            Le conducteur a une CB
            </span>
          </div>
          <div className={[stylesForm.formCol].join(" ")}>
          <Controller
              name="has_bank_card"
              control={control}
              defaultValue={true}
              render={({ field }) => (
                <Switch
                  checked={field.value}
                  onChange={(checked) => field.onChange(checked)}
                />
              )}
            />
          </div>
        </div>

        <div className={styles.bottomBtnContainer}>
          <Button
            label="Valider"
            containerProps={{
              className: stylesForm.buttonSubmit,
            }}
            onClick={handleSubmit(onSubmit)}
          />

          <Button
            label="Annuler"
            type="secondary"
            containerProps={{
              className: stylesForm.buttonCancel,
            }}
            onClick={() => onRequestClose && onRequestClose()}
          />
        </div>

      </div>
    </Modal>
  )
}