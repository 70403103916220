import React from 'react';
import styles from './RecapItinerary.module.scss';
import { ConfirmSectionType, RecapSectionType } from 'src/Stores/CurrentMissionTypes/MissionContextType';
import CabTravelPath from '../CabTravelPath/CabTravelPath';
import formatDuration from 'src/helpers/duration';
import RecapTravelPathSection from '../../../Components/RecapTravelPathSection/RecapTravelPathSection';


function sectionIsRecapSectionType(section: ConfirmSectionType | RecapSectionType): section is RecapSectionType {
  return (section as RecapSectionType).actions !== undefined;
}

export default function RecapItinerary({
  displayPrice = true,
  sections,
  containerProps: { className, ...containerProps } = {},
}: {
  displayPrice?: boolean;
  sections: Array<ConfirmSectionType | RecapSectionType>;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}) {

  let isTaxiBeforeMain = true;

  return (
    <div className={[styles.recapItinerary, className].join(" ")} {...containerProps}>

      {sections.map((section, index) => {
        if (section.type !== "taxi") {
          isTaxiBeforeMain = false;
        }

        // If is confirmSectionType, console log the section and return null.
        const isRecap = sectionIsRecapSectionType(section);

        if (section.type === "vrental") {
          return null;
        }

        const displayTaxiCorrespondance = (
          (sections[index - 1]?.type === "taxi" && section.type !== "taxi")
          ||
          (sections[index - 1]?.type !== "taxi" && sections[index - 1]?.type !== undefined && section.type === "taxi")
        );


        return (
          <React.Fragment key={index}>
            {displayTaxiCorrespondance && (
              <div className={styles.taxiSeparator}>
                30 mins de correspondance
              </div>
            )}
            {section.type === "taxi" ? (
              <CabTravelPath
                key={index}
                isTaxiBeforeMain={isTaxiBeforeMain}
                section={section}
                containerProps={{
                  className: styles.travelPath,
                }}
              />
            ) : (
              <RecapTravelPathSection
                key={index}
                section={section}
                containerProps={{
                  className: styles.travelPath,
                }}
                right={(
                  <div className={styles.travelInfoContainer}>
                    <div className={styles.textInfosContainer}>
                      {section.type === "train" && (
                      <div className={styles.infoPrimaryLabel}>
                        {section.label}
                      </div>
                      )}
                      <div className={styles.infoSecondaryLabel}>
                        {formatDuration({
                          unit: 'minutes',
                          value: section.durationMinutes
                        })}
                      </div>
                    </div>

                    <div className={styles.priceContainer}>
                      {displayPrice && !!section.price && (
                      <div className={styles.price}>
                        {section.priceIsEstimate ? "Estimation de " : ""}{section.price.formatted} {section.price.currency.symbol}
                      </div>
                      )}
                      {isRecap && section.actions?.removeConnectingTaxi && (
                      <a href="" onClick={(e) => {
                        e.preventDefault();
                        return false;
                      }} className={styles.delete}>Supprimer</a>
                      )}
                    </div>

                  </div>
                )}
              />
            )}
          </React.Fragment>
        )

      })}
    </div>
  )
}