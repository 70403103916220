import { CheckValid } from '@assets/icons/icons';
import styles from './AddPhoto.module.scss';
import Button from '@components/Button/Button';


function ButtonPhoto({
  setPhoto,
  label,
  disabled,
  className,
}: {
  setPhoto: (photo: File | null) => void;
  label: string;
  disabled?: boolean;
  className?: string;
}) {
  return (
    <Button
      label={label}

      onClick={() => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/png, image/jpeg';
        input.onchange = (event) => {
          const files = (event.target as HTMLInputElement).files;
          if (files && files.length > 0) {
            setPhoto(files[0]);
          }
        }
        input.click();
      }}
      type="secondary"
      containerProps={{
        className,
        disabled,
      }}
    />
  )
}

export default function AddPhoto({
  photo,
  setPhoto,
  disabled,
}: {
  photo: File | null;
  setPhoto: (photo: File | null) => void;
  disabled?: boolean;
}) {


  return (
    <div className={styles.container}>

      {photo && (
        <div className={styles.photoContainer}>
          <CheckValid />
          <div className={styles.photoName}>
            {photo.name.substring(0, 25)}{photo.name.length > 25 ? "..." : ""}
          </div>
          <ButtonPhoto
            disabled={disabled}
            setPhoto={setPhoto}
            label="Modifier"
            className={styles.modifyPhotoButton}
          />
        </div>
      )}

      {!photo && (
        <ButtonPhoto
          disabled={disabled}
          setPhoto={setPhoto}
          label="Ajouter une photo"
          className='w-full'
        />
      )}
    </div>
  )
}