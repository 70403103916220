import Button from '@components/Button/Button';
import Modal from '@components/Modal/Modal';
import styles from './ModalGoodWill.module.scss'
import { useState } from 'react';
import { Oval } from 'react-loader-spinner';
import Select from '@components/Select/Select';
import InputText from '@components/Inputs/InputText/InputText';
import { NumericFormat } from 'react-number-format';
import { RecapStepType } from 'src/Stores/CurrentMissionTypes/MissionContextType';
import { useCurrentMission } from 'src/Stores/CurrentMission';


export default function ModalGoodWill({
  onRequestClose,
  recap,
}: {
  onRequestClose: () => void;
  recap: RecapStepType
}) {

  const remainingCostsAmount = recap.remainingCosts?.amount || 0;
  const { callAction } = useCurrentMission();
  const [ confirmLoading, setConfirmLoading ] = useState(false);
  const [amount, setAmount] = useState<number>(recap?.goodwill?.amount || remainingCostsAmount);
  const [goodwillTypeID, setGoodwillTypeID] = useState<string | undefined>((typeof recap.selectedGoodwillType === 'string' ? recap.selectedGoodwillType : undefined) || (typeof recap.selectedGoodwillType === 'object' ? recap.selectedGoodwillType?.id : undefined) || recap.goodwillTypes?.[0]?.id || undefined);

  const initialRemainingCosts = (recap.initialRemainingCosts || recap.remainingCosts);
  const initialRemainingCostsAmount = (initialRemainingCosts?.amount || 0.)

  let newRemainingCost = amount > initialRemainingCostsAmount ? 0 : remainingCostsAmount - amount;
  if (recap?.goodwill?.amount !== undefined) {
    newRemainingCost += recap.goodwill.amount;
  }
  const newRemainingCostFormatted = new Intl.NumberFormat('fr-FR', { style: 'currency', currency: recap.remainingCosts?.currency?.code || "EUR" }).format(newRemainingCost / 100);

  const layout = "default";

  let errorMessage: string | undefined;
  if (amount > initialRemainingCostsAmount) {
    errorMessage = "Le montant pris en charge ne peut pas être supérieur au reste à charge";
  }

  const hasError = !!errorMessage;

  const onConfirm = async () => {

    setConfirmLoading(true);

    const data = {
      type: goodwillTypeID,
      amount: amount / 100,
    }

    const action = recap.actions?.setGoodwillAction;

    if (!action) {
      setConfirmLoading(false);
      return;
    }

    try {
      await callAction(action, data);
      onRequestClose();

    } catch (e) {
      console.error("ERROR", e);
      setConfirmLoading(false);
    }
  }

  return (
    <Modal
      title={"Geste commercial"}
      containerProps={{
        style: {
          maxWidth: 500,
          minWidth: 500,
        },
        className: `${styles.modalConfirmContainer} ${layout ? styles[layout] : ""}`
      }}
      onRequestClose={onRequestClose}
      layout={layout}
    >
      <div className={styles.formContainer}>
        <div className={`flex flex-row justify-between align-center ${styles.line}`}>
          <div className={styles.labelTitle}>Reste à charge</div>
          <div className={styles.valueTitle}>{initialRemainingCosts?.formatted || '0,00'} {initialRemainingCosts?.currency.symbol || '€'}</div>
        </div>


        <div className={`flex flex-row justify-between align-center ${styles.line}`}>
          <Select
            containerProps={{
              className: "w-full"
            }}
            label='Type'
            placeholder={'Geste commercial'}
            optionsV2={(recap.goodwillTypes || []).map((type) => ({
              label: type.label,
              value: type.id
            }))}
            onChangeIndex={(index) => {
              if (index === null) {
                setGoodwillTypeID(undefined);
                return;
              }
              setGoodwillTypeID(recap.goodwillTypes?.[index]?.id);
            }}
            selectedIndex={recap.goodwillTypes?.findIndex((type) => type.id === goodwillTypeID)}
          />
        </div>

         <div className={`flex flex-row justify-between align-center ${styles.line}`}>

          <InputText
            disabled={false}
            label={"Montant pris en charge"}
            errorMsg={errorMessage}
            readOnly={false}
            containerProps={{
              className: 'w-full',
            }}

            inputComponent={(
              <NumericFormat
                allowedDecimalSeparators={[".", ","]}
                decimalScale={2}
                thousandSeparator={" "}
                decimalSeparator=','
                value={amount / 100}
                onChange={(e) => {
                  const amountCentimes = Math.floor(parseFloat(e.target.value.replace(/ /g, "").replace(/,/g, ".")) * 100);
                  if (isNaN(amountCentimes) || amountCentimes < 0) {
                    setAmount(0);
                    return;
                  }
                  setAmount(amountCentimes);
                }}
              />
            )}
          />

          <div className="flex flex-col justify-center">€</div>

        </div>


        <div className={`flex flex-row justify-between align-center ${styles.line}`}>
          <div className={styles.labelTitle}>Nouveau reste à charge</div>
          <div className={styles.valueTitle}>{newRemainingCostFormatted}</div>
        </div>

      </div>
      <div className={styles.buttonActions}>

        <Button
          label={"Annuler"}
          onClick={() => {
            onRequestClose();
          }}
          type="secondary"
        />

        <Button
          onClick={async () => {
            const responseConfirm = onConfirm();
            if (responseConfirm instanceof Promise) {
              await responseConfirm;
            }
          }}
          containerProps={{
            style: {
              height: 50,
            },
            disabled: hasError || confirmLoading
          }}
        >{confirmLoading ? <Oval
            height={20}
            width={20}
            color="white"
            secondaryColor="white"
            strokeWidth={2}
            strokeWidthSecondary={2}
          /> : "Valider"}</Button>
      </div>
    </Modal>
  )
}