import PageContainer from 'src/layout/Page/PageContainer/PageContainer';
import styles from './Passengers.module.scss';
import layoutStyles from '../../CurrentMission.module.scss';

import Button from '@components/Button/Button';
import Header from 'src/layout/Header/Header';

import { Oval } from 'react-loader-spinner'
import CurrentMissionVerticalSteps from '../../CurrentMissionVerticalSteps';
import usePassengers from './Components/usePassenger';
import PassengerInput from './Components/PassengerInput/PassengerInput';
import SendLinkButton from 'src/Pages/_Components/SendSefCareLinks/SendLinkButton';
import { isAppEnvProductionLike } from 'src/appEnv';
import useSendLinkButtons from 'src/Pages/_Components/SendSefCareLinks/useSendLinkButtons';

export default function PassengersPage({
  containerProps: { className, ...containerProps } = {},
}: {
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}) {

  const {
    missionContext,
    control,
    handleSubmit,
    errors,
    loading,
    onSubmit,
    comments,
    setComments,
    travelerForm,
    watch,
    stepIsReadOnly,
  } = usePassengers();
  const { canSendSelfCareLink } = useSendLinkButtons();

  if (!missionContext?.steps?.travelerForm) {
    return null;
  }

  return (
    <PageContainer
      containerProps={{
        ...containerProps,
        className: [layoutStyles.container, styles.container, className].join(" "),
      }}
    >

      {loading && (
        <div className={styles.loadingFullScreen}>
          <Oval
            height={40}
            width={40}
            color="white"
            secondaryColor="white"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      )}

      <Header
        type='mission'
      />

      <form data-testid="step-travelerForm" className={styles.form} onSubmit={handleSubmit(onSubmit)}>

        <div className={[layoutStyles.content, styles.content].join(" ")}>

          <CurrentMissionVerticalSteps
            displayBackButton
          />

          <div className={styles.mainContentContainer}>

            {!travelerForm ? null : (
              <div className={styles.formContainer}>
                <div className={styles.sectionHeader}>
                  <div>
                    {missionContext.steps.searchTrain ? 'Passagers' : 'Contact de la réservation '}
                  </div>

                 {canSendSelfCareLink && (
                  <SendLinkButton
                    type='search'
                    label='Envoyer le lien vers le Selfcare'
                    containerProps={{
                      className: styles.sendLinkButton,
                      disabled: isAppEnvProductionLike(),
                    }}
                  />
                 )}
                </div>

                <div className={styles.passengerFormContainer}>

                  {travelerForm.passengerInformation.map((passenger, indexPassenger) => (
                    <div data-testid="passenger-form" key={passenger.id} className={styles.passengerFormContainer}>
                      <div className={styles.passengerFormTitle}>
                        {passenger.title}
                        {indexPassenger === 0 && " > Le bénéficiaire" }
                      </div>

                      <div className={styles.passengerForm}>

                        {(passenger.fields || []).map((fields, fieldLineIndex) => (
                          <div key={fieldLineIndex} className={styles.passengerFormRow}>
                            {(fields || []).map((fieldGroup) => {
                              return (
                                <PassengerInput
                                  control={control}
                                  errors={errors}
                                  formField={fieldGroup}
                                  passenger={passenger}
                                  readOnly={stepIsReadOnly}
                                  loading={loading}
                                  watch={watch}
                                  key={fieldGroup.name}
                                />
                              );
                            })}
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>

                {travelerForm.commentForms && (
                  <div className={styles.sectionComments}>
                    {travelerForm.commentForms.map((comment) => (
                      <div className={styles.comment} key={comment.id}>
                        <p>{comment.label}</p>
                        <textarea
                          placeholder={''}
                          readOnly={stepIsReadOnly}
                          className={`${styles.commentInput} ${comment.readOnly ? styles.commentInputReadOnly : ""}`}
                          value={comments[`${comment.id}`] || ""}
                          onChange={(e) => {

                            if (comment.readOnly) {
                              return;
                            }
                            if (comment.maxLength && e.target.value.length > comment.maxLength) {
                              return;
                            }
                            setComments({
                              ...comments,
                              [`${comment.id}`]: e.target.value,
                            });
                          }}
                        />
                      </div>
                    ))}
                  </div>
                )}

                <div className={styles.footer}>
                <Button
                  label="Valider"
                  testid='button-validate-travelerform'

                  containerProps={{
                    className: styles.buttonValidate,
                    disabled: loading || stepIsReadOnly,
                    type: "submit",
                  }}
                />
              </div>

              </div>
            )}

          </div>

          <div className={styles.centerPlaceHolder} />
        </div>

      </form>

    </PageContainer>
  )
}
