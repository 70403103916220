import React from 'react';
import styles from './Steps.module.scss';
import { ReactComponent as Image } from './Images/phone_preview.svg';

export default function StepSearch({
  containerProps: { className, ...containerProps } = {},
}: {
  containerProps?: Omit<React.HTMLAttributes<HTMLDivElement>, 'children'>;
}) {

  return (
    <div className={[styles.container, className].join(" ")} {...containerProps}>

      <div className={styles.text}>
        Renseignez vos critères et trouvez la solution qui répond le mieux à vos besoins
      </div>

      <Image />

    </div>
  )
}