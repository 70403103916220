import styles from './NewMission.module.scss';
import PageContainer from 'src/layout/Page/PageContainer/PageContainer';
import HeaderSelfCare from 'src/layout/Header/Header.selfcare';
import Button from '@components/Button/Button';
import FooterLinks from 'src/layout/SelfCare/Footer/FooterLinks';
import { useState } from 'react';
import PhoneChatModal from 'src/layout/Header/PhoneChatModal/PhoneChatModal';
import { useNavigate } from 'react-router-dom';

export default function NewMission() {

  const [displayModalPhoneChat, setDisplayModalPhoneChat] = useState(false);
  const navigate = useNavigate();

  return (
    <PageContainer
      containerProps={{
        className: [styles.container, 'selfcare'].join(" "),
      }}
    >
      {displayModalPhoneChat && <PhoneChatModal onRequestClose={() => setDisplayModalPhoneChat(false)} />}

      <HeaderSelfCare
        displayMissionProgressBar
        displayBackButton
        displayWarrantiesButton={true}
        forceProgressBarPercent={50}
        title='Nouvelle prestation'
        onBackButtonClick={() => navigate(-1)}
      />

      <div className={styles.content}>

        <div className={styles.contentContainer}>

          <p>
            Vous avez besoin d’une nouvelle prestation de mobilité pendant l’immobilisation de votre véhicule?
          </p>
          <p>
            Afin de réserver une nouvelle prestation de mobilité vous devez joindre un conseiller afin qu’il génère un nouveau lien de réservation.
          </p>

          <div className={styles.actions}>

            <Button
              label="Appeler un conseiller"
              onClick={() => setDisplayModalPhoneChat(true)}
              type="secondary"
              containerProps={{
                className: styles.actionButton,
              }}
            />

          </div>

        </div>


        <div className={styles.footerContainer}>
          <FooterLinks
            containerProps={{
              className: styles.footer,
            }}
          />
        </div>

      </div>



    </PageContainer>
  )
}