import styles from './Welcome.module.scss';
import PageContainer from 'src/layout/Page/PageContainer/PageContainer';
import HeaderSelfCare from 'src/layout/Header/Header.selfcare';
import selcare_welcome_person from '@assets/img/selfcare_welcome_person.svg';
import selcare_welcome_phone from '@assets/img/selfcare_welcome_phone.svg';
import Button from '@components/Button/Button';
import { useNavigate } from 'react-router-dom';
import OnboardingButton from '../Components/OnBoarding/OnboardingButton';
import { useCurrentMission } from 'src/Stores/CurrentMission';
import { Warranty } from '@assets/icons/icons';

export default function WelcomeSelfCare() {

  const navigate = useNavigate();
  const { mission } = useCurrentMission();

  if (!mission) {
    throw new Error("Mission not found")
  }

  return (
    <PageContainer
      containerProps={{
        className: [styles.container, 'selfcare'].join(" "),
      }}
    >

      <HeaderSelfCare
        displayMissionProgressBar
        forceProgressBarPercent={10}
      />

      <div className={styles.content}>

        <div className={styles.title}>
          Bienvenue {`${mission?.clientFirstname} ${mission?.clientLastname}`}
        </div>

        <p>
          Votre véhicule {mission?.licensePlate} est immobilisé pour cause de panne.
        </p>

        <p>
          <b>Trouvez une solution de mobilité</b> afin de poursuivre votre voyage.
        </p>

        <div className={styles.imgInfoContainer}>
          <img src={selcare_welcome_person} alt="Illustration panne" />
          <img src={selcare_welcome_phone} alt="Illustration téléphone" />
        </div>

        <div className={styles.actions}>
          <Button
            label="Commencer"
            onClick={() => navigate("mission")}
            type="primary"
            containerProps={{
              className: styles.actionButton,
            }}
          />

          <Button
            onClick={() => navigate("warranties")}
            type="secondary"
            containerProps={{
              className: styles.actionButton,
            }}
          >
            <Warranty />
            <div className={styles.actionButtonLabel}>Vos garanties</div>
          </Button>

          <OnboardingButton
            label="Tutoriel"
            type="secondary"
            containerProps={{
              className: styles.actionButton,
            }}
          />

        </div>

      </div>

    </PageContainer>
  )
}