import styles from './ItinerarySolutions.selfcare.module.scss';
import layoutStyles from '../../CurrentMission.selfcare.module.scss';
import Button from '@components/Button/Button';

import PageContainer from 'src/layout/Page/PageContainer/PageContainer';
import FilterModal from 'src/Pages/_Components/Filter/FilterV3/FilterModal/FilterModal';
import useItinerarySolutions from './useItinerarySolutions';
import HeaderSelfCare from 'src/layout/Header/Header.selfcare';
import Itinerary from '@components/Itinerary/SelfCare/Itinerary';
import HeaderChangeItinerarySelfCare from './HeaderChangeItinerarySelfCare/HeaderChangeItinerarySelfCare';
import FilterByTransportType from './FilterByTransportType/FilterByTransportType';
import AlertBox from '@components/AlertBox/AlertBox';
import { SolutionType } from 'src/Stores/CurrentMissionTypes/MissionContextType';
import { useCurrentMission } from 'src/Stores/CurrentMission';
import { format } from 'date-fns';
import { useSort } from 'src/Pages/_Components/Filter/Sort/useSort';
import { Oval } from 'react-loader-spinner';

export default function ItinerarySolutions() {

  const stepIsReadOnly = false;

  const itinerarySolutions = useItinerarySolutions({
    forceReadOnly: stepIsReadOnly
  });
  const { mission, missionContext } = useCurrentMission();

  const {
    sortType,
    setSortType,
    sortedItems: itinerarySolutionsSorted,
  } = useSort<"price" | "duration" | "arrivalDate", SolutionType>({
    items: itinerarySolutions?.solutionsToDisplay || [],
    getSortValue(item, sortType) {
      if (sortType === "price") {
        return item.bestPrice.amount;
      }
      if (sortType === "duration") {
        return item.durationInMinutes;
      }
      if (sortType === "arrivalDate") {
        return new Date(item.arrivalDatetime);
      }
      return 0;
    }
  });

  if (!itinerarySolutions || !itinerarySolutionsSorted) {
    return null;
  }



  const {
    extendSearchCallAction,
    extendActionLoading,
    isFilteredByPoi,
    onSelectItinerary,
    setDisplayOnlyTop3,
    idsFiltered,
    resetFilters,
    modalProps,
    solutionsByTransportTypes,
    mainTransportTypeFilter,
    setMainTransportTypeFilter,
    onSubmit,
    onClickButtonFilter,
  } = itinerarySolutions;


  const dateFrom = missionContext?.steps?.search?.formData?.datetime ? new Date(missionContext?.steps?.search?.formData?.datetime) : null;

  return (
    <>

      <FilterModal
        {...modalProps}
        sortTypes={[
          {
            key: "price",
            label: "Prix",
          }, {
            key: "duration",
            label: "Temps de trajet",
          }, {
            key: "arrivalDate",
            label: "Arrivé le plus tôt",
          }
        ]}
        sortTypeSelected={sortType}
        onChangeSort={sortKey => {
          setSortType(sortKey as "price" | "duration" | "arrivalDate" | null);
        }}
      />

      <PageContainer
        containerProps={{
          className: [layoutStyles.container, styles.container].join(" "),
        }}
      >

        <HeaderSelfCare
          displayMissionProgressBar
        >
          <HeaderChangeItinerarySelfCare
            onClickButtonFilter={onClickButtonFilter}
            origin={mission?.searchData?.origin?.name || ""}
            destination={mission?.searchData?.destination?.name || ""}
            date={dateFrom ? format(dateFrom, "dd/MM HH:mm") : ""}
          />

          <FilterByTransportType
            mainTransportTypeFilter={mainTransportTypeFilter}
            solutionsByTransportTypes={solutionsByTransportTypes}
            setMainTransportTypeFilter={(mainTransport) => {
              setMainTransportTypeFilter(mainTransport);
              setDisplayOnlyTop3(mainTransport === null);
            }}
          />
        </HeaderSelfCare>

        <div data-testid="step-solutions" className={`${layoutStyles.content} pb-4`}>

          <div className={styles.resultsContainer}>

            {itinerarySolutionsSorted.some((solution) => solution.mainTransportType.id === "vrental") && (
              <AlertBox
                type="warning"
                displayIcon
                text={[
                  "Une carte bancaire est requise pour le dépôt de garantie lors de la location de voiture.",
                  "La durée d’obtention de permis minimum requise est 3 ans et l’âge minimum requis est de 23 ans.",
                ]}
                containerProps={{
                  className: styles.alertBox,
                }}
              />
            )}

            <div className={styles.results}>

              {itinerarySolutionsSorted.length === 0 && (
                <>
                  <div className={styles.noResults}>
                    Aucun résultat ne correspond à votre recherche actuelle. Pour obtenir plus de résultats, veuillez élargir vos critères de recherche.
                  </div>
                  <Button
                    type="secondary"
                    label="Réinitialiser les filtres"
                    onClick={() => {
                      resetFilters();
                    }}
                  />
                </>
              )}

              {itinerarySolutionsSorted.map((solution, i) => {

                let title = `Via ${solution.mainOrigin.name}`;
                let title2: string | undefined =  solution.mainDestination?.name;
                if (solution.mainOrigin?.city) {
                  title = `Via ${solution.mainOrigin.name}`;
                  title2 = `${solution.mainOrigin.city ? " " + solution.mainOrigin.city : ""}`;
                }
                return (
                  idsFiltered[solution.id] ? (
                  <Itinerary
                    key={solution.id}
                    readOnly={stepIsReadOnly}
                    title={isFilteredByPoi && solution.mainDestination ? `Vers ${solution.mainDestination.name}` : title}
                    title2={isFilteredByPoi ? undefined : title2}
                    departureDateTimeIso={solution.departureDatetime}
                    arrivalDateTimeIso={solution.arrivalDatetime}
                    durationInMinutes={solution.durationInMinutes}
                    price={{
                      amount: solution.bestPrice.amount,
                      formatted: solution.bestPrice.formatted,
                      currency: {
                        symbol: solution.bestPrice.currency.symbol,
                        code: solution.bestPrice.currency.code,
                      },
                    }}
                    selected={false}
                    onSelect={({
                      selected,
                      selectedAllSectionsIndex,
                      onSelectParams,
                    }: {
                      selected: boolean;
                      selectedAllSectionsIndex: number[];
                      onSelectParams?: {
                        index: number;
                      };
                    }) => {
                      onSelectItinerary({
                        selected,
                        selectedAllSectionsIndex,
                        onSelectParams,
                      });
                      onSubmit(onSelectParams?.index || 0);
                    }}
                    onSelectParams={{
                      index: i,
                    }}
                    containerProps={{
                      className: styles.itinerary,
                    }}
                    mainTransportType={solution.mainTransportType.id}
                    sections={solution.sections}
                    co2Emission={solution.kgCO2e ? {
                      value: solution.kgCO2e,
                      level: solution.ecologyRating
                    } : undefined}
                    remainingCosts={solution.remainingCosts}
                    transportations={solution.sections.map((section) => section.type)}
                    tags={solution.tags}
                    showRemainingCosts={true} // FIXME?
                  />
                  ): null
                );
              })}

              {extendSearchCallAction && (
                <div className={`flex flex-row items-center ${styles.expandSearchButtonContainer}`}>
                  <Button
                    type='secondary'
                    label="Etendre la recherche"
                    containerProps={{
                      className: styles.expandSearchButton,
                      disabled: extendActionLoading || stepIsReadOnly,
                    }}
                    onClick={extendSearchCallAction}
                  />
                  {extendActionLoading && (
                    <Oval
                      height={40}
                      width={40}
                      strokeWidth={5}
                      strokeWidthSecondary={5}
                    />
                  )}
                </div>
              )}

            </div>

          </div>

        </div>

      </PageContainer>
    </>
  )
}