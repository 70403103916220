import styles from './styles.module.scss';
import stylesForm from '../../../Form.module.scss';
import Select from '@components/Select/Select';
import Button from '@components/Button/Button';
import Modal from '@components/Modal/Modal';
import { Close } from '@assets/icons/icons';
import { useRef, useState } from 'react';
import { useCurrentMission } from 'src/Stores/CurrentMission';
import { PassengerType } from './PassengerType';
import PassengerCards from './PassengerCards';
import usePassengersCardsForm from './usePassengersCardsForm';
import { isSelfCare } from 'src/Stores/AppContext';

export default function ModalPassengers({
  passengers,
  onChange,
  onRequestClose,

  canAddCards = true,
  containerProps: { className, ...containerProps } = {},
}: {
  passengers: Array<PassengerType>;
  onChange?: (passengers: Array<PassengerType>) => any;
  onRequestClose?: () => any;

  canAddCards?: boolean;
  containerProps?: React.HTMLAttributes<HTMLFormElement>;
}) {

  const [passengersList, setPassengersList] = useState<Array<PassengerType>>(passengers);
  const { missionContext} = useCurrentMission();
  const cardTypes = missionContext?.steps?.search?.form?.cardTypes || [];
  const ageCategories = missionContext?.steps?.search?.form?.ageCategories || [];
  const passengersCardsForm = usePassengersCardsForm({
    passengers,
    cardTypes
  });

  const { passengersCards, removePassenger, checkErrors } = passengersCardsForm;

  const listRef = useRef<HTMLDivElement>(null);
  const passengersListElement = listRef.current;

  const addNewPassenger = (ageCategory: "adult" | "young" | "senior") => {
    setPassengersList([
      ...passengersList,
      {
        ageCategory,
      }
    ]);
    // Scroll list to bottom
    if (passengersListElement) {
      setTimeout(() => {
        passengersListElement.scrollTop = passengersListElement.scrollHeight;
      }, 0)
    }
  }

  const deletePassengerIndex = (index: number) => {
    setPassengersList(passengersList => passengersList.filter((_, i) => i !== index));
    removePassenger(index);
  }

  const onSubmit = () => {

    const hasErrors = checkErrors();
    if (hasErrors) {
      return;
    }

    const passengersCardsMap = passengersCards.map((passengerCards) => {
      const cards: {[key: string]: { number: string }} = {};
      for (let cardIndex = 0; cardIndex < passengerCards.length; cardIndex++) {
        const passengerCard = passengerCards[cardIndex];
        if (!passengerCard.cardId) {
          continue;
        }
        cards[passengerCard.cardId] = {
          number: passengerCard.cardNumber || '',
        };
      }
      return cards;
    });

    const updatedPassengersList = passengersList.map((passenger, index) => {
      return {
        ...passenger,
        ...(passengersCardsMap[index] ? {
          cards: passengersCardsMap[index],
        } : {}),
      };
    });

    setPassengersList(updatedPassengersList);
    onChange && onChange(updatedPassengersList);
    onRequestClose && onRequestClose();
  };


  const youngSelectOptions: Array<number> = [];
  const youngAgeCategory = ageCategories.find(ageCategory => ageCategory.id === "young");
  if (youngAgeCategory
    && youngAgeCategory.ageMinimum !== undefined
    && youngAgeCategory.ageMaximum !== undefined
    && youngAgeCategory.ageMinimum !== null
    && youngAgeCategory.ageMaximum !== null
  ) {
    for (let i = youngAgeCategory.ageMinimum; i <= youngAgeCategory.ageMaximum; i++) {
      youngSelectOptions.push(i);
    }
  }

  return (
    <Modal
      onRequestClose={onRequestClose}
      title='Ajouter des passagers'
      containerProps={{
        className: styles.container,
      }}
      contentRef={listRef}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          onSubmit();
          return false;
        }}
        className={[stylesForm.form, styles.optionsModalContainer, className].join(" ")} {...containerProps}
      >
        <div className={styles.passengersList}>
          {passengersList.map((passenger, i) => {
            return (
              <div className={`${stylesForm.formRowBlock} ${stylesForm.formRowPassengersList}`}  key={i}>
                <div className={styles.passengerTitle}>{`Passager ${i + 1}${i === 0 ? ' > le bénéficiaire' : ''}`}</div>
                <div className={`${stylesForm.formRow} ${styles.formRowSelectAge}`}>
                  <Select
                    containerProps={{
                      className: styles.selectAgeType,
                    }}
                    label='Catégorie d’âge'
                    placeholder={'Passager' + (i+1)}
                    options={ageCategories.map(ageCategory => ageCategory.label)}
                    // options={ageCategories.filter((ageCategory) => i!=0 || ageCategory.id != 'young').map(ageCategory => ageCategory.label)}
                    onChangeIndex={(index) => {
                      if (index === null) {
                        return;
                      }
                      setPassengersList(passengersList.map((p, j) => {
                        if (j === i) {
                          return {
                            ...p,
                            ageCategory: ageCategories[index].id,
                          }
                        }
                        return p;
                      }));
                    }}
                    selectedIndex={passenger.ageCategory ? ageCategories.findIndex(ageCategory => ageCategory.id === passenger.ageCategory) : null}
                  />

                  {passenger.ageCategory === "young" && (() => {

                    const youngSelectOptionsFiltered = i === 0 ? youngSelectOptions.filter(a => a >= 18) : youngSelectOptions;

                    return (
                      <div className={styles.selectAgeContainer}>
                        <Select
                          placeholder={'age'}
                          label='Age'
                          containerProps={{
                            className: styles.selectAge,
                          }}
                          options={youngSelectOptionsFiltered.map(age => age + " ans")}
                          onChangeIndex={(index) => {
                            if (index === null) {
                              return;
                            }
                            setPassengersList(passengersList.map((p, j) => {
                              if (j === i) {
                                return {
                                  ...p,
                                  age: youngSelectOptionsFiltered[index],
                                }
                              }
                              return p;
                            }));
                          }}
                          selectedIndex={(passenger.age !== null) ? youngSelectOptionsFiltered.findIndex(age => age === passenger.age) : null}
                        />
                      </div>

                    )
                  })()}

                  {i > 0 && (
                    <div
                      className={styles.deletePassenger}
                      onClick={() => {
                        deletePassengerIndex(i);
                      }}
                    >
                      <Close />
                    </div>
                  )}
                </div>

                {canAddCards && (<PassengerCards
                  passengersCardsForm={passengersCardsForm}
                  passengerIndex={i}
                />)}

              </div>
            )
          })}
        </div>


        <div className={styles.addPassenger}>
          <div className={styles.addPassengerTitle}>
            Ajouter un passager
          </div>

          <div className={styles.addPassengerButtons}>

            {ageCategories.map((ageCategory) => (
              <Button
                key={ageCategory.id}
                label={(
                  <div>
                    <div>{ageCategory.label}</div>
                    {/* {(ageCategory.ageMinimum || ageCategory.ageMaximum) && (
                      <div>{[ageCategory.ageMinimum, ageCategory.ageMaximum].filter(a => {
                        return a !== null && a !== undefined;
                      }).join(" - ")}</div>
                    )} */}
                  </div>
                )}
                type="secondary"
                disableUpperCase
                bigBorder

                onClick={() => {
                  addNewPassenger(ageCategory.id);
                }}
                containerProps={{
                  className: styles.addPassengerButton,
                }}
              />
            ))}
          </div>
        </div>

        <div className={styles.bottomBtnContainer}>
          <Button
            onClick={() => onSubmit()}
            label='Valider'
            containerProps={{ className: stylesForm.buttonSubmit }}
          />

          {!isSelfCare() && (
          <Button
            label="Annuler"
            type="secondary"
            containerProps={{
              className: stylesForm.buttonCancel,
            }}
            onClick={() => onRequestClose && onRequestClose()}
          />
          )}
        </div>

      </form>
    </Modal>
  )
}
