import Button from '@components/Button/Button';
import Modal from '@components/Modal/Modal';
import styles from './SendLinkModal.module.scss'
import { useState } from 'react';
import { IconCheckNotValid, IconCheckValid } from '@assets/icons/icons';
import { useCurrentMission } from 'src/Stores/CurrentMission';
import { Oval } from 'react-loader-spinner';
import PhoneNumberInput from '@components/PhoneNumberInput/PhoneNumberInput';
import { useMobilePreview } from 'src/Stores/MobilePreviewStore';
import { isAppEnvProductionLike } from 'src/appEnv';


export default function ModalSendLink({
  type,
  onRequestClose,
}: {
  type: 'search' | 'refund';
  onRequestClose: () => void;
}) {

  const [selfCareLink, setSelfCareLink] = useState<string | null>(null);
  const { mission, callAction } = useCurrentMission();
  const [ currentState, setCurrentState ] = useState<'send' | 'success' | 'error'>('send');
  const [ confirmLoading, setConfirmLoading ] = useState(false);
  const [ clientPhone, setClientPhone ] = useState<string>(mission?.clientPhone || "fr ");
  const [ changePhone, setChangePhone ] = useState<boolean>(false);
  const { openInPreview } = useMobilePreview();

  const layout = "default";
  let title: React.ReactNode | string = "";
  let confirmText = "";
  let cancelText = "";
  let message = "";

  const displayClientPhone = clientPhone.replace(/^[a-zA-Z]{2} /g, "");

  switch (true) {
    case currentState === "send" && type === "search":
      title = "Envoyer le lien vers l’application de réservation";
      message = "Vous avez la possibilité de proposer au client de continuer sa réservation de mobilité seul. Pour ce faire, un lien vers notre application Selfcare lui sera envoyé par SMS au numéro suivant : " + (changePhone ? "" : displayClientPhone);
      confirmText = "Envoyer le lien vers le Selfcare";
      cancelText = "Annuler";
      break;
    case currentState === "send" && type === "refund":
      title = "Envoyer le lien vers le formulaire de remboursement";
      message = "Vous avez la possibilité de rediriger le client vers un formulaire de remboursement. Pour ce faire, un lien vers le Selfcare lui sera envoyé par SMS au numéro suivant : " + (changePhone ? "" : displayClientPhone);
      confirmText = "Envoyer le lien vers le Selfcare";
      cancelText = "Annuler";
      break;
    case currentState === "success":
      title = (
        <div className='flex items-center justify-center flex-col gap-6'>
          <IconCheckValid width={40} height={40} />
          <div>Lien envoyé avec succès</div>
        </div>
      );
      message = "";
      confirmText = "Renvoyer le lien vers le Selfcare";
      cancelText = "Annuler";
      break;

    case currentState === "error":
      title = (
        <div className='flex items-center justify-center flex-col gap-6'>
          <IconCheckNotValid width={40} height={40} />
          <div>Erreur lors de l'envoi du lien</div>
        </div>
      );
      message = "";
      confirmText = "Renvoyer le lien vers le Selfcare";
      cancelText = "Annuler";
      break;
  }


  const onConfirm = async () => {

    setConfirmLoading(true);

    let newCurrentState: 'success' | 'send' | 'error' = 'send';
    let action = null;
    let data = null;
    switch (type) {
      case 'search':
        action = mission?.actions?.sendSelfCareLink;
        data = {
          phone: clientPhone,
        };
        break;
      case 'refund':
        action = mission?.actions?.sendRefundFlowLink;
        data = {
          phone: clientPhone,
        };
        break;
      default:
        break;
    }

    try {

      if (!action) {
        throw new Error("missing action");
      }

      const response = await callAction(action, data, {
        returnData: true,
      });
      const selfCareLink = response.data.selfCareLink;

      if (!selfCareLink) {
        throw new Error("missing selfCareLink");
      }

      setSelfCareLink(selfCareLink);
      newCurrentState = 'success';

    } catch (e) {
      newCurrentState = 'error';
    }


    setCurrentState(newCurrentState);
    setConfirmLoading(false);
  }

  const onCancel = () => {
    onRequestClose();
  }


  return (
    <Modal
      title={title}
      containerProps={{
        style: {
          maxWidth: 600,
          minWidth: 500,
        },
        className: `${styles.modalConfirmContainer} ${layout ? styles[layout] : ""}`
      }}
      onRequestClose={onRequestClose}
      layout={layout}
    >
      <div className={styles.messageContainer}>
        {message}
      </div>

      {currentState === "send" && (
      <div className={styles.inputContainer}>
        {!changePhone && (
          <div
            onClick={() => {
              setChangePhone(true);
            }}
            className={styles.changePhoneAction}
          >Modifier le numéro de téléphone</div>
        )}

        {changePhone && (

          <PhoneNumberInput
            value={clientPhone}
            onChange={(value) => {
              setClientPhone(value);
            }}
          />
        )}
      </div>
      )}

      <div className={styles.buttonActions}>

        <Button
          onClick={async () => {
            if (currentState !== "send") {
              setCurrentState("send");
              return;
            }
            const responseConfirm = onConfirm();
            if (responseConfirm instanceof Promise) {
              await responseConfirm;
            }
          }}
          containerProps={{
            style: {
              height: 50,
            }
          }}
        >{confirmLoading ? <Oval
            height={20}
            width={20}
            color="white"
            secondaryColor="white"
            strokeWidth={2}
            strokeWidthSecondary={2}
          /> : confirmText}</Button>

        <Button
          label={cancelText}
          onClick={() => {
            onCancel();
          }}
          type="secondary"
        />

        {!isAppEnvProductionLike() && selfCareLink && (
          <>
            <Button
              testid='button-preview-selfcare'
              label="Voir la version selfcare"
              type='primary'
              containerProps={{
                className: styles.button,
                style: { marginRight: 10 }
              }}
              onClick={() => {
                const url = new URL(selfCareLink);
                const newUrl = new URL(window.location.href);
                newUrl.pathname = url.pathname;
                newUrl.search = url.search;
                newUrl.search += "&licensePlate="+mission?.licensePlate;
                openInPreview(newUrl.toString());
              }}
            />

            <Button
              testid='button-preview-new-window-selfcare'
              label="Voir la version selfcare (nouvelle fenetre)"
              type='primary'
              containerProps={{
                className: styles.button,
                style: { marginRight: 10 }
              }}
              onClick={() => {
                const url = new URL(selfCareLink);
                const newUrl = new URL(window.location.href);
                newUrl.pathname = url.pathname;
                newUrl.search = url.search;
                newUrl.search += "&licensePlate="+mission?.licensePlate;
                window.open(newUrl.toString(), '_blank');
              }}
            />
          </>
        )}

      </div>
    </Modal>
  )
}