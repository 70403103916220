import styles from './VRentalSelectedPage.module.scss';

import { useMemo, useState } from 'react';
import { useCurrentMission } from 'src/Stores/CurrentMission';
import PageContainer from 'src/layout/Page/PageContainer/PageContainer';
import useFilterVRentals from 'src/Pages/_Components/Filter/useFilterVRentals';
import FilterModal from 'src/Pages/_Components/Filter/FilterModal';
import HeaderSelfCare from 'src/layout/Header/Header.selfcare';
import { format } from 'date-fns';
import CarChoiceItemSelfCare from '@components/CarChoiceItem/SelfCare/CarChoiceItem';
import VrentalAgencyDetail from '@components/VrentalAgencyDetail/VrentalAgencyDetail';
import Checkbox from '@components/Checkbox/Checkbox';
import Button from '@components/Button/Button';
import { RentalType } from 'src/Stores/CurrentMissionTypes/MissionContextType';

export default function VRentalSelectedPage({
  rental,
  onBack,

  optionsIncluded,
}: {
  rental: RentalType;
  onBack: () => void;

  optionsIncluded?: Array<string>;
  optionsAvailable?: Array<string>;
}) {

  const { mission, missionContext, callAction } = useCurrentMission();
  const [loading, setLoading] = useState(false);

  const [ checkboxes, setCheckboxes ] = useState<Array<{
    key: string;
    label: string;
    checked: boolean;
  }>>([
    {
      key: 'creditCard',
      label: "J'ai une carte de crédit pour le dépôt de garantie à l'agence",
      checked: false,
    },
    {
      key: 'age',
      label: `J’ai plus de ${rental.minAge ?? 23} ans et plus de ${rental.minLicenseAge ?? 3} ans de permis`,
      checked: false,
    }
  ]);

  const stepIsReadOnly = false;

  const {
    idsFiltered,
    modalProps,
  } = useFilterVRentals(missionContext?.steps?.searchVrental?.rentals || [], missionContext?.steps?.searchVrental?.filters || {});

  const rentals = useMemo(() => {
    return (missionContext?.steps.searchVrental?.rentals || []).filter((rental) => {
      return idsFiltered[rental.id];
    });
  }, [idsFiltered, missionContext?.steps.searchVrental?.rentals])

  if (!mission || !missionContext || !missionContext?.steps?.searchVrental) {
    return null;
  }

  const agencies = missionContext.steps.searchVrental.agencies.filter((agency) => {
    return rentals.some((rental) => rental.agencyID === agency.id);
  });

  const rentalAgency = agencies.find((agency) => agency.id === rental.agencyID);

  if (!agencies || !rentals || !rentalAgency || !rental) {
    return null;
  }

  const submitSelectedSolution = async (selectedSolution: RentalType) => {
    if (stepIsReadOnly) {
      return;
    }
    setLoading(true);
    try {
      const action = selectedSolution.chooseAction;
      if (!action) {
        throw new Error("Missing chooseAction");
      }
      await callAction(action);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }

  }


  const daysCount = missionContext.steps.searchVrental.daysCount;

  let locationDuration = `Location ${daysCount} jour${daysCount > 1 ? 's' : ''}`;
  if (missionContext.steps.search?.formData?.datetime && missionContext.steps.search?.formData?.returnDatetime) {
    const dateStart = new Date(missionContext.steps.search?.formData?.datetime);
    const dateEnd = new Date(missionContext.steps.search?.formData?.returnDatetime);
    locationDuration += ` du ${format(dateStart, 'dd/MM HH:mm')} au ${format(dateEnd, 'dd/MM HH:mm')}`;
  } else if (missionContext.steps.search?.formData?.datetime) {
    const dateStart = new Date(missionContext.steps.search?.formData?.datetime);
    locationDuration += ` à partir du ${format(dateStart, 'dd/MM HH:mm')}`;
  }

  return (
    <>

      <FilterModal
        {...modalProps}
      />

      <PageContainer
        containerProps={{
          className: styles.container,
        }}
      >

        <HeaderSelfCare
          displayMissionProgressBar
          title='Détails du véhicule de location'
          displayBackButton
          onBackButtonClick={() => {
            onBack();
          }}
        />

        <div className={styles.content}>

          <div className={styles.optionDateLimit}>
            Option valable jusqu’à {format((new Date()).setHours(new Date().getHours() + 2), 'HH:mm')}
          </div>

          <div className={styles.carImageContainer}>
            <img src={rental.imgURL} alt="car" width="50%" />
          </div>

          <div className={styles.locationDuration}>
            {locationDuration}
          </div>

          <CarChoiceItemSelfCare
            readOnly={stepIsReadOnly}
            distance={`${rental.agencyDistanceKM} km`}
            carName={rental.selfCareLabel || ""}
            agencyLabel={rental.agencyLabel}
            tags={rental.tags}
            carConditions={rental.conditions}
            co2Emission={rental.kgCO2e ? {
              value: `${rental.kgCO2e} kgCO2e`,
              level: rental.ecologyRating
            } : undefined}
            remainingCosts={rental.remainingCosts}
            carOptions={Array.isArray(rental.descriptions) ? rental.descriptions : [rental.descriptions || ""]}
            containerProps={{
              className: styles.carDetail,
            }}
          />

          <VrentalAgencyDetail
            title="Agence de retrait du véhicule"
            address={rental.agencyLabel}
            hours={rentalAgency.openingHours || []}
            imgUrl={rental.renterImgURL}
            sameReturnAgency={true}
            containerProps={{
              className: styles.agencyDetail,
            }}
          />

          <div className={styles.optionsIncludedContainer}>
            <div className={styles.optionsIncludedTitle}>Inclus</div>
            <ul style={{ listStyleType: "initial" }} className={styles.optionsIncludedList}>
              {(optionsIncluded||[]).map((option) => (
                <li key={option}>{option}</li>
              ))}
            </ul>
          </div>

          <div className={styles.checkboxesContainer}>
          {checkboxes.map(({
            label,
            checked,
            key
          }) => (
            <Checkbox
              key={key}
              label={label}
              labelClassName={styles.checkboxLabel}
              checked={checked}
              onChange={(checked) => {
                setCheckboxes((checkboxes) => {
                  const newcheckboxes = checkboxes.map((checkbox) => {
                    if (checkbox.key === key) {
                      return {
                        ...checkbox,
                        checked,
                      };
                    }
                    return checkbox;
                  });
                  return newcheckboxes;
                });
              }}
            />
          ))}
          </div>

        </div>

        <div className={styles.buttonsContainer}>
          <Button
            label="Valider"
            onClick={() => submitSelectedSolution(rental)}
            type="primary"
            containerProps={{
              disabled: loading || stepIsReadOnly || !checkboxes.every((checkbox) => checkbox.checked),
            }}
          />

          <Button
            label="Retour aux résultats"
            onClick={() => {
              onBack();
            }}
            type="secondary"
            containerProps={{
              disabled: loading || stepIsReadOnly || !checkboxes.every((checkbox) => checkbox.checked),
            }}
          />
        </div>

      </PageContainer>
    </>

  )
}