import React from 'react';
import styles from './Steps.module.scss';
import { ReactComponent as Image } from './Images/mobility.svg';

export default function StepMobility({
  containerProps: { className, ...containerProps } = {},
}: {
  containerProps?: Omit<React.HTMLAttributes<HTMLDivElement>, 'children'>;
}) {

  return (
    <div className={[styles.container, className].join(" ")} {...containerProps}>

      <div className={styles.text}>
        Réservez votre mobilité et voyagez en toute sérénité.
      </div>

      <Image />

    </div>
  )
}