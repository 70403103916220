import styles from './CarChoiceItem.module.scss';
import { Leaf } from '@assets/icons/icons';
import SelectableContent from '@components/SelectableContent/SelectableContent';
import { TagKey } from 'src/Stores/CurrentMissionTypes/MissionContextType';
import CarChoiceItemTag from './CarChoiceItemTag';
import { PriceType } from 'src/models/Price';

export type CarChoiceItemProps = {
  title?: string;
  readOnly?: boolean;
  distance?: string;
  carName?: string;
  carConditions?: string;
  carImg?: string;
  agencyImg?: string;
  carOptions?: string[];
  price?: PriceType;
  priceLeftToPay?: string;
  priceInfo?: string;

  tags?: {
    [key in TagKey]?: boolean;
  };
  selected?: boolean;
  onSelect?: () => any;

  co2Emission?: {
    value: string;
    level: 1 | 2 | 3;
    color?: 'default' | 'warning';
  };

  optionsIncluded?: Array<string>;
  optionsAvailable?: Array<string>;

  containerProps?: React.HTMLAttributes<HTMLDivElement>;
};

export default function CarChoiceItem({
  selected = false,
  readOnly = false,
  title = "",
  distance,
  carName,
  carConditions,
  carImg,
  agencyImg,
  carOptions = [],
  price,
  priceLeftToPay,
  priceInfo,
  tags = {},
  co2Emission,

  optionsIncluded = [],
  optionsAvailable = [],

  onSelect = () => void 0,
  containerProps,
}: CarChoiceItemProps) {

  return (
    <SelectableContent

      selected={selected}
      readOnly={readOnly}
      onSelect={onSelect}

      containerProps={containerProps}
      content={(
        <div className={styles.infosContainer}>
          <div className={styles.infosHead}>
            <div className={styles.title}>{title}</div>
            {distance && (
              <div className={styles.distance}>{distance}</div>
            )}
            <div className={styles.tags}>

              {(Object.keys(tags) as TagKey[]).map((tagKey, i) => tags[tagKey] ? (
                <CarChoiceItemTag
                  key={i}
                  tagKey={tagKey}
                  containerProps={{ className: "mr-2" }}
                />
              ): null)}

            </div>
          </div>

          <div className={styles.infosContent}>

            <div className={styles.carImageContainer}>
              {agencyImg && (
                <img src={agencyImg} className={styles.agencyImage} alt="" />
              )}
              {carImg && (
                <img src={carImg} className={styles.carImage} alt="" width={80} />
              )}
            </div>

            <div className={styles.carDetails}>
              <div className={styles.carName}>{carName}</div>
              {carConditions && (
                  <div className={styles.carConditions}>{carConditions}</div>
              )}
              {carOptions && carOptions.length > 0 && (
              <div className={styles.carOptions}>
                {carOptions.map((option, i) => (
                  <div key={i} className={styles.carOption}>{option}</div>
                ))}
              </div>
              )}
            </div>

            <div className={styles.carPrice}>
              <div className={styles.priceValue}>{price?.formatted + " " + price?.currency.symbol}</div>
              <div className={styles.priceWarning}>{priceLeftToPay}</div>
              <div className={styles.priceInfo}>{priceInfo}</div>
            </div>

            {co2Emission && (
              <div className={styles.co2Infos}>
                <div className={[styles.co2Icons, co2Emission.color === "warning" ? styles.co2IconsWarning : ""].join(" ")}>
                  {co2Emission.level >= 1 && <Leaf width={12} height={12} fill={undefined} />}
                  {co2Emission.level >= 2 && <Leaf width={12} height={12} />}
                  {co2Emission.level >= 3 && <Leaf width={12} height={12} />}
                </div>
                <div className={styles.co2Value}>{co2Emission.value}</div>
              </div>
            )}

          </div>

        </div>
      )}

      expandedContent={(optionsIncluded?.length || optionsAvailable?.length) ? (
        <div className={styles.detailsContainer}>
          {optionsIncluded && optionsIncluded.length > 0 && (
          <div className={styles.detailsColumn}>
            <div className={styles.detailsTitle}>Inclus</div>
            <div className={styles.detailsContent}>
              {optionsIncluded.map((option, i) => (
                <div key={i} className={styles.detailsItem}>{option}</div>
              ))}
            </div>
          </div>
          )}
          {optionsAvailable && optionsAvailable.length && (
          <div className={styles.detailsColumn}>
            <div className={styles.detailsTitle}>Suppléments disponibles</div>
            <div className={styles.detailsContent}>
              {optionsAvailable.map((option, i) => (
                <div key={i} className={styles.detailsItem}>{option}</div>
              ))}
            </div>
          </div>
          )}
        </div>
      ) : null}
    />

  )
}