import { Oval } from 'react-loader-spinner'
import stylesCabTravelPath from './CabTravelPath.module.scss';
import { Add } from '@assets/icons/icons';
import { useState } from 'react';
import { useCurrentMission } from 'src/Stores/CurrentMission';
import { statusLabels } from 'src/helpers/statusLabels'
import Tag from '@components/Tag/Tag';
import { ApiAction } from 'src/models/ApiAction';
import { isSectionTypeConfirm, isSectionTypeRecap } from './helpers';
import RecapTravelPathSection from './Components/RecapTravelPathSection/RecapTravelPathSection';
import { ConfirmSectionType, RecapSectionType } from 'src/Stores/CurrentMissionTypes/MissionContextType';
import formatDuration from 'src/helpers/duration';

export default function CabTravelPath({
  section,
  containerProps: { ...containerProps } = {},
}: {
  section: RecapSectionType | ConfirmSectionType;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}) {

  const { callAction: callMissionAction } = useCurrentMission();
  const [loading, setLoading] = useState(false);

  const callAction = async (action: ApiAction) => {
    setLoading(true);
    try {
      await callMissionAction(action);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  }

  const deleteTaxi = async () => {
    if (!isSectionTypeRecap(section)) {
      return false;
    }
    if (!section.actions.removeConnectingTaxi) {
      return;
    }
    await callAction(section.actions.removeConnectingTaxi);
  }

  const addTaxi = async () => {
    if (!isSectionTypeRecap(section)) {
      return false;
    }
    if (!section.actions.addConnectingTaxi) {
      return;
    }

    await Promise.all([
      callAction(section.actions.addConnectingTaxi)
    ]);
  }

  let rightContent: React.ReactNode = null;
  switch (true) {

    case loading:
      rightContent = (
        <div className={stylesCabTravelPath.travelInfoContainer}>
          <div className={stylesCabTravelPath.textInfosContainer}>
            <div className={stylesCabTravelPath.addContainer}>
               <Oval
                  height={18}
                  width={18}
                  color="black"
                  secondaryColor="black"
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
            </div>
          </div>
        </div>
      );
      break;

    case isSectionTypeRecap(section) && !!section.actions?.addConnectingTaxi:
      rightContent = (
        <div className={stylesCabTravelPath.travelInfoContainer}>
          <div className={stylesCabTravelPath.textInfosContainer}>
            <div className={stylesCabTravelPath.addContainer} onClick={() => {
              addTaxi();
            }}>
              <Add />
              Ajouter un taxi
            </div>
          </div>
        </div>
      );
      break;

    case isSectionTypeRecap(section):
      rightContent = (
        <div className={stylesCabTravelPath.travelInfoContainer}>
          <div className='flex flex-row justify-start items-center'>
            <div className={stylesCabTravelPath.textInfosContainer}>
              <div className={stylesCabTravelPath.infoSecondaryLabel}>
                {formatDuration({
                  value: section.durationMinutes,
                  unit: 'minutes',
                })}
              </div>
            </div>
          </div>



          {!!section.price && (
            <div className={stylesCabTravelPath.priceContainer}>

              {section.statusWithLabel && section.statusWithLabel.isDisplayed && section.statusWithLabel.id && statusLabels[section.statusWithLabel.id] && (
                <Tag
                  {...(section.statusWithLabel.href ? {
                    onClick: () => {
                      window.open(section.statusWithLabel.href, '_blank');
                    }
                  } : {})}
                  label={section.statusWithLabel.label}
                  color={statusLabels[section.statusWithLabel.id]?.color || 'grey'}
                  containerProps={{ className: stylesCabTravelPath.tagRecapStatus}}
                />
              )}

              <div className={stylesCabTravelPath.price}>
                {section.priceIsEstimate ? "Estimation de " : ""}{section.price.formatted} {section.price.currency.symbol}
              </div>
              {isSectionTypeRecap(section) && !!section.actions.removeConnectingTaxi && (
                <a href="" onClick={(e) => {
                  e.preventDefault();
                  deleteTaxi();
                  return false;
                }} className={stylesCabTravelPath.delete}>Supprimer</a>
              )}
            </div>
          )}
        </div>
      );
      break;

    case isSectionTypeConfirm(section):
      rightContent = (
        <div className={stylesCabTravelPath.travelInfoContainer}>
          <div className='flex flex-row justify-start items-center'>
            <div className={stylesCabTravelPath.textInfosContainer}>
              <div className={stylesCabTravelPath.infoSecondaryLabel}>
                {section.durationMinutes} mins
              </div>
            </div>
          </div>


          <div className={stylesCabTravelPath.priceContainer}>

            {section.statusWithLabel && section.statusWithLabel.isDisplayed && section.statusWithLabel.id && statusLabels[section.statusWithLabel.id] && (
              <Tag
                {...(section.statusWithLabel.href ? {
                  onClick: () => {
                    window.open(section.statusWithLabel.href, '_blank');
                  }
                } : {})}
                label={section.statusWithLabel.label}
                color={statusLabels[section.statusWithLabel.id]?.color || 'grey'}
                containerProps={{ className: stylesCabTravelPath.tagRecapStatus}}
              />
            )}
            {!!section.price && (
            <div className={stylesCabTravelPath.price}>
              {section.priceIsEstimate ? "Estimation de " : ""}{section.price.formatted} {section.price.currency.symbol}
            </div>
            )}
          </div>

        </div>
      );
      break;

    default:
      break;
  }

  return (
    <RecapTravelPathSection
      section={section}
      containerProps={{
        ...containerProps,
        className: `${containerProps.className} ${stylesCabTravelPath.travelPath}`
      }}
      right={rightContent}
    />
  )
}