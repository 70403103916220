import { IconShare } from "@assets/icons/icons";
import Button from "@components/Button/Button";
import { useState } from "react";
import ModalSendLink from "./SendLinkModal";

export default function SendLinkButton({
  type,
  label,
  buttonType = 'primary',
  containerProps,
}: {
  type: 'search' | 'refund';
  label?: string;
  buttonType?: 'primary' | 'secondary';
  containerProps?: Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'>;
}) {

  const [displayModalSendLink, setDisplayModalSendLink] = useState<{ open: false } | {
    type: 'search' | 'refund';
    open: true;
  }>({
    open: false
  });

  return (
    <>
      {displayModalSendLink && displayModalSendLink.open === true && (
        <ModalSendLink
          type={displayModalSendLink.type}
          onRequestClose={() => setDisplayModalSendLink({
            open: false
          })}
        />
      )}
      <Button
        type={buttonType}
        onClick={() => {
          setDisplayModalSendLink({
            open: true,
            type
          });
        }}
        containerProps={containerProps}
      >
        {type === 'refund' ? (
          <>
            <IconShare /> {label || 'Remboursement'}
          </>
        ) : null}
        {type === 'search' ? (
          <>
            <IconShare /> {label || "Recherche de mobilité"}
          </>
        ) : null}
      </Button>

    </>
  )
}