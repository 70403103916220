import PageContainer from 'src/layout/Page/PageContainer/PageContainer';
import styles from './ItineraryRecapPage.module.scss';
import layoutStyles from '../../../CurrentMission.selfcare.module.scss';
import Button from '@components/Button/Button';
import { Oval } from 'react-loader-spinner'
import VRentalSection from './Components/VRentalSection/VRentalSection';
import useRecap from '../useRecap';
import HeaderSelfCare from 'src/layout/Header/Header.selfcare';
import RecapDateTime from '../Components/RecapDateTime/RecapDateTime';
import RecapItinerary from './Components/RecapItinerary/RecapItinerary';
import Checkbox from '@components/Checkbox/Checkbox';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCurrentMission } from 'src/Stores/CurrentMission';
import useMissionContextRefreshInterval from 'src/Hooks/useMissionContextRefreshInterval';
import { useConfirmAlert } from 'src/Stores/ConfirmAlertStore';

export default function ItineraryRecapPage({
  containerProps: { className, ...containerProps } = {},
}: {
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}) {

  const {
    alert
  } = useConfirmAlert();
  const navigation = useNavigate();
  const { callAction } = useCurrentMission();
  const recapController = useRecap();
  const [cgvChecked, setCgvChecked] = useState(false);
  const [loadingSelfCarePayment, setLoadingSelfCarePayment] = useState(false);

  const paymentProcessing = recapController?.missionContext.steps?.recap?.paymentStatus?.id === 'processing';

  useMissionContextRefreshInterval({
    refreshInterval: 2000,
    enabled: paymentProcessing
  })

  useEffect(() => {
    if (recapController?.recap?.status?.id === 'option_expired') {
      alert({
        message_type: 'error',
        title: "Option expirée",
        message: "Votre option a expiré. Veuillez relancer une recherche afin de trouver une nouvelle solution de mobilité.",
        confirmLabel: "Fermer",
        layout: "minimal",
      });
    }
  }, [recapController?.recap?.status?.id, alert]);

  if (!recapController) {
    return null;
  }

  const {
    loading,
    stepAction,
    recapDate,
    isTaxiOnly,
    validUntilDate,
    optionIsToday,
    currentStep,
    recap,

  } = recapController;

  const canPay = recap.actions?.startSelfCarePaymentAction || (recap.stripeClientSecret && recap.stripePublicKey);

  const sectionsWithoutArrival = recap.sections.filter(section => section.type !== 'arrival');
  const arrivalSections = recap.sections.filter(section => section.type === 'arrival');



  return (
    <PageContainer
      containerProps={{
        ...containerProps,
        className: [layoutStyles.container, styles.container, className].join(" "),
      }}
    >

      {loading && (
        <div className={styles.loadingFullScreen}>
          <Oval
            height={40}
            width={40}
            color="white"
            secondaryColor="white"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      )}

      <HeaderSelfCare
        title="Votre récapitulatif"
        displayBackButton={true}
        displayMissionProgressBar
        backBtnConfirm={{
          title: "Vous êtes sur le point de perdre votre option",
          message: "En revenant à l’étape précédente vous allez perdre votre option. Êtes-vous sûr de vouloir poursuivre ?",
          cancelLabel: "Annuler",
          confirmLabel: "Valider",
        }}
      />

      <div data-testid="step-recap" className={[styles.content].join(" ")}>

        <RecapDateTime
          isTaxiOnly={isTaxiOnly}
          recapDate={recapDate}
          validUntil={recap.validUntil}
          validUntilDate={validUntilDate}
          optionIsToday={optionIsToday}
          durationMinutes={recap.durationMinutes}
          displayDuration={false}
          containerProps={{
            className: styles.recapDateTime,
          }}
        />

        {sectionsWithoutArrival.length > 0 && (
          <RecapItinerary
            sections={sectionsWithoutArrival}
            displayPrice={false}
            containerProps={{
              className: styles.recapItinerary,
            }}
          />
        )}

        {recap.vrentalsBySectionID && Object.keys(recap.vrentalsBySectionID).map((sectionID) => (
          <VRentalSection
            key={sectionID}
            rental={recap.vrentalsBySectionID[sectionID]}
            containerProps={{
              className: styles.vRentalSection,
            }}
          />
        ))}

        {arrivalSections.length > 0 && (
          <RecapItinerary
              sections={arrivalSections}
          />
        )}

        <div className={styles.recapCostsContainer}>

          <div className={styles.recapRemainingCosts}>
            {recap.remainingCosts === null || recap.remainingCosts?.amount === 0 ? (
              <>
                <span>Coût total</span>
                <div className={styles.remainingCostsIncluded}>
                  Inclus dans votre contrat
                </div>
              </>
            ) : (
              <>
                <span>Reste à charge</span>
                <span className={styles.remainingCostsNotIncluded}>{`${recap.remainingCosts?.formatted} ${recap.totalPrice?.currency.symbol}`}</span>
              </>
            )}
          </div>

        </div>

        <div className={styles.recapAction}>
          {(canPay || recap.actions.confirmAction) && (
            <>
              {!paymentProcessing && (
              <Checkbox
                label='J’accepte les CGV de Allianz bi-partner'
                labelClassName={styles.checkboxLabel}
                containerProps={{
                  className: styles.checkBox,

                }}
                checked={cgvChecked}
                disabled={loadingSelfCarePayment}
                onChange={(checked) => {
                  setCgvChecked(checked);
                }}
              />
              )}

              {canPay && (
                <Button
                  testid='button-launch-payment'
                  label={paymentProcessing ? (
                    "Paiement en cours..."
                  ) : (
                    loadingSelfCarePayment ? (
                      <Oval
                        height={20}
                        width={20}
                        color="white"
                        secondaryColor="white"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                      />
                    ) : "Confirmer et payer"
                  )}

                  type="primary"
                  containerProps={{
                    className: 'w-full mb-4',
                    disabled: loading || !cgvChecked || paymentProcessing
                  }}
                  onClick={async () => {
                    if (recap.stripeClientSecret && recap.stripePublicKey) {
                      navigation('../payment');
                    } else if (recap.actions.startSelfCarePaymentAction) {
                      setLoadingSelfCarePayment(true);
                      try {
                        await callAction(recap.actions.startSelfCarePaymentAction);
                        navigation('../payment');
                      } catch (e) {
                        console.error(e);
                      } finally {
                        setLoadingSelfCarePayment(false);
                      }
                    }
                  }}
                />
              )}

              {!recap.actions.sendPaymentLinkAction && recap.actions.confirmAction && (
                <Button
                  testid='button-confirm-recap'
                  label={"Valider la prestation"}
                  type="primary"
                  containerProps={{
                    className: 'w-full mb-4',
                    disabled: loading || !cgvChecked,
                  }}
                  onClick={() => {
                    stepAction(currentStep, 'confirm');
                  }}
                />
              )}
          </>
          )}
        </div>

      </div>

    </PageContainer>
  )
}