import axios from "axios";
import { useAuthStore } from "../Stores/AuthStore";
import { useConfirmAlert } from "src/Stores/ConfirmAlertStore";
import i18n from "src/Locales/i18n";
import { isSelfCare } from "src/Stores/AppContext";
import { useAuthStoreLicensePlate } from "src/Stores/AuthStoreLicensePlate";

class ErrorApiUrlNotSet extends Error {
  constructor() {
    super("API URL not set");
  }
}

export const testApi = axios.create({
  // timeout: 1000,
  baseURL: 'http://127.0.0.1:8080/api/v1',
  headers: {
    'Content-Type': 'application/json',
  },
});

export const publicApi = axios.create({
  // timeout: 1000,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});
publicApi.interceptors.request.use((config) => {
  if (!window.env?.PUBLIC_API_URL) throw new ErrorApiUrlNotSet();
  config.baseURL = window.env.PUBLIC_API_URL;

  config.url = config.url?.replace("/api/v1", "");
  return config;
});

export function getAuthenticatedApiInstance(options?: {
  errorHandlerEnabled?: boolean;
  prefixIfSelfCare?: boolean;
}) {

  const {
    errorHandlerEnabled = true,
    prefixIfSelfCare = true
  } = options || { errorHandlerEnabled: true, prefixIfSelfCare: true };

  const authenticatedApi = axios.create({
    // timeout: 1000,
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: true,
  });
  authenticatedApi.interceptors.request.use((config) => {

    const language = i18n.language;

    if (!window.env?.PUBLIC_API_URL) throw new ErrorApiUrlNotSet();
    config.baseURL = window.env.PUBLIC_API_URL;

    if (prefixIfSelfCare && isSelfCare()) {
      config.baseURL = window.env.PUBLIC_API_URL + "/self-care";
    }

    config.url = config.url?.replace("/api/v1", "");
    // if selfcare => config.url?.replace("/api/v1/self-care", "");
    config.headers['Accept-Language'] = language;

    return config;
  });

  // reload the page if the user is not authenticated
  authenticatedApi.interceptors.response.use(
    (response) => response,
    (error) => {

      if (error instanceof ErrorApiUrlNotSet) {
        window.location.href = "/";
        return Promise.reject(error);
      }

      if (!errorHandlerEnabled) {
        return Promise.reject(error);
      }

      if (error.response?.status === 401) {

        useAuthStore.getState().cleanup();
        useAuthStoreLicensePlate.getState().cleanup();

        if (isSelfCare()) {
          window.location.href = "/self-care";
        } else {
          window.location.href = "/";
        }

        return;
      }

      if (isSelfCare()) {
        window.location.href = "/self-care/error";
      } else {
        useConfirmAlert.getState().alert({
          title: "Erreur",
          message_type: "default",
          layout: "default",
          message: error.response?.data?.error || "Une erreur est survenue",
        });
      }

      return Promise.reject(error);
    }
  );

  return authenticatedApi;
}


export const authenticatedApi = getAuthenticatedApiInstance();

