import styles from './PassengerInput.module.scss';
import { FormField } from 'src/Stores/CurrentMissionTypes/MissionContextType';
import { Control, Controller, FieldErrors, UseFormWatch } from 'react-hook-form';
import InputText from '@components/Inputs/InputText/InputText';
import DatePicker from '@components/DatePicker/DatePicker';
import Switcher from '@components/Switcher/Switcher';
import Select from '@components/Select/Select';
import { getPhoneNumberFormat } from '@components/PhoneNumberInput/usePhoneNumberFormat';
import PhoneNumberInput from '@components/PhoneNumberInput/PhoneNumberInput';
import Card from '../../Fields/Card';
import { isSelfCare } from 'src/Stores/AppContext';
import { Man, Woman } from '@assets/icons/icons';
import { PassengerInformationType } from 'src/Stores/CurrentMissionTypes/MissionContextType';

export default function PassengerInput({
  passenger,
  control,
  errors,
  watch,
  formField,

  loading,
  readOnly,

  className,
  isHidden = false,
}: {
  formField: FormField;
  passenger: PassengerInformationType;
  control: Control<any>;
  errors: FieldErrors<any>;
  watch: UseFormWatch<any>;
  readOnly: boolean;
  loading: boolean;
  isHidden?: boolean;
  className?: string;
}) {

  const fieldGroup = {...formField};

  if (isHidden) {
    fieldGroup.required = false;
  }

  let checkRegexOnChangeEvent = false;
  const inputName = passenger.id + "_" + fieldGroup.name;
  const errorMsg = errors[inputName]?.message as string || undefined;

  switch (fieldGroup.type) {
    case 'text':
    case 'email':

      if (fieldGroup.name === "lastName" || fieldGroup.name === "firstName") {
        if (fieldGroup.regex === "") {
          fieldGroup.regex = "^[A-Za-zÀ-ÖØ-öø-ÿ- ]+$";
          checkRegexOnChangeEvent = true;
        }
      }

      return (
        <div key={inputName} className={`${styles.passengerFormCol} ${className}`}>
          <Controller
            name={inputName}
            control={control}
            rules={{
              required: {
                value: fieldGroup.required,
                message: "Ne peut pas être vide"
              },
              pattern: fieldGroup.regex ? {
                value: new RegExp(fieldGroup.regex),
                message: "Format invalide" + (fieldGroup.placeholder ? ` (ex: ${fieldGroup.placeholder})` : ""),
              } : undefined,
            }}
            render={({ field }) => (
              <InputText
                testid={fieldGroup.name}
                disabled={loading || readOnly}
                label={fieldGroup.label}
                errorMsg={errorMsg}
                readOnly={readOnly}
                containerProps={{
                  className: 'w-full',
                }}
                inputProps={{
                  type: "text",
                  placeholder: fieldGroup.placeholder,
                  value: field.value,
                  onChange: (e) => {
                    const regex = new RegExp(fieldGroup.regex);
                    if (!checkRegexOnChangeEvent) {
                      field.onChange(e.target.value);
                      return;
                    }
                    if (!e.target.value) {
                      field.onChange(e.target.value);
                      return;
                    }
                    if (regex.test(e.target.value)) {
                      field.onChange(e.target.value);
                    }
                  },
                }}
              />
            )}
          />
      </div>
      );


    case 'date':
      return (
        <div key={inputName} className={styles.passengerFormCol}>
          <Controller
            name={inputName}
            control={control}
            rules={{
              required: {
                value: fieldGroup.required,
                message: "Ne peut pas être vide"
              },
            }}
            render={({ field }) => {
              const date = field.value ? new Date(field.value) : null;
              const today = new Date();
              return (
                <DatePicker
                  testid={fieldGroup.name}
                  disabled={loading || readOnly}
                  label={fieldGroup.label}
                  errorMsg={errorMsg}
                  placeholder='Date de naissance'
                  date={date}
                  readOnly={readOnly}
                  onChange={(date) => {
                    if (!date) {
                      return;
                    }
                    date.setHours(0);
                    date.setMinutes(0);
                    date.setSeconds(0);
                    const dateIso = date.toISOString();
                    field.onChange(dateIso);
                  }}
                  datePickerProps={{
                    showYearDropdown: true,
                    showMonthDropdown: true,
                    popperPlacement: "top",
                    dropdownMode: "select",
                  }}
                  datePickerContainerProps={{
                    className: styles.datePickerContainer,

                  }}
                  minDate={fieldGroup.minimum ? new Date(fieldGroup.minimum) : null}
                  maxDate={fieldGroup.maximum ? new Date(fieldGroup.maximum) : today}
                />
              )
            }}
          />
        </div>
      );

    case 'switch':
      return (
        <div key={inputName} className={styles.passengerFormCol}>
          <Controller
            name={inputName}
            control={control}
            rules={{
              required: {
                value: fieldGroup.required,
                message: "Ne peut pas être vide"
              },
            }}
            render={({ field }) => {

              const selectedIndex = fieldGroup.options.findIndex((option) => option.value === field.value);

              return (
                <Switcher
                  testid={fieldGroup.name}
                  containerProps={{
                    className: 'w-full',
                  }}
                  buttonsMode
                  fullWidth={isSelfCare()}
                  errorMsg={errorMsg}
                  disabled={loading || readOnly}
                  options={fieldGroup.options.map((option) => {
                    let icon: React.ReactNode = null;
                    switch (option.value) {
                      case "MR":
                        icon = <Man />;
                        break;
                      case "MS":
                        icon = <Woman />;
                        break;
                    }
                    return {
                      value: option.value,
                      label: option.label,
                      icon
                    }
                  })}
                  selectedIndex={selectedIndex}
                  onChangeIndex={(selectedIndex) => {
                    if (selectedIndex === null) {
                      field.onChange(null);
                      return;
                    }
                    field.onChange(fieldGroup.options[selectedIndex].value);
                  }}
                />
              )
            }}
          />
        </div>
      );

    case 'select':
      return (
        <div key={inputName} className={styles.passengerFormCol}>
          <Controller
            name={inputName}
            control={control}
            rules={{
              required: {
                value: fieldGroup.required,
                message: "Ne peut pas être vide"
              },
            }}
            render={({ field }) => (
              <Select
                testid={fieldGroup.name}
                disabled={loading || readOnly}
                label={fieldGroup.label}
                errorMsg={errorMsg}
                placeholder={fieldGroup.placeholder}
                optionsV2={fieldGroup.options.map((option) => ({
                  value: option.value,
                  label: option.label,
                  searchValue: option.label,
                }))}
                selectedIndex={fieldGroup.options.findIndex((option) => option.value === field.value)}
                onChangeIndex={(selectedIndex) => {
                  if (selectedIndex === null) {
                    field.onChange(null);
                    return;
                  }
                  field.onChange(fieldGroup.options[selectedIndex].value);
                }}
              />
            )}
          />
        </div>
      );

    case 'complex':
    case 'phonenumber':
      return (
        <div key={inputName} className={styles.passengerFormCol}>
          <Controller
            name={inputName}
            control={control}
            rules={{
              required: {
                value: fieldGroup.required,
                message: "Ne peut pas être vide"
              },
              validate: {
                notEmpty: (v: string) => {

                  const phoneExploded = v.split(" ");
                  if (phoneExploded.length < 3 || !phoneExploded[2]) {
                    return "Ne peut pas être vide";
                  }

                  const regexpResult = new RegExp(getPhoneNumberFormat(watch(inputName)).regexp).exec(v);
                  if (!regexpResult) {
                    return "Format invalide";
                  }
                  return true;
                }
              },
            }}
            render={({ field }) => (
              <PhoneNumberInput
                testid={fieldGroup.name}
                disabled={loading || readOnly}
                label="Numéro de téléphone"
                errorMsg={errorMsg}
                containerProps={{
                  className: "w-full",
                }}
                value={field.value}
                onChange={(value) => {
                  field.onChange(value);
                }}
              />
            )}
          />
        </div>
      );

      case 'cards':
        return (
          <Card
            key={inputName}
            inputName={inputName}
            control={control}
            loading={loading}
            disabled={readOnly}
            field={fieldGroup}
            errors={errors}
          />
        );
    }

  return null;
}