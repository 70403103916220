import { CheckboxChecked } from '@assets/icons/icons';
import styles from './Checkbox.module.scss';
import React from 'react';

export default function Checkbox({
  checked = false,
  disabled,
  onChange,
  label,
  testid,
  containerProps: { className, ...containerProps } = {},
  labelClassName,
}: {
  checked?: boolean;
  disabled?: boolean;
  onChange?: (checked: boolean) => any;
  label?: string;
  testid?: string;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
  labelClassName?: string;
}) {


  return (
    <div
      data-testid={testid}
      className={[styles.container, disabled ? styles.disabled : "", className].join(" ")}
      {...containerProps}
      onClick={() => {
        if (disabled) return;
        onChange && onChange(!checked);
      }}
    >
      <div
        className={[styles.checkbox, checked ? styles.checked : ""].join(" ")}
      >
        {checked && (
          <CheckboxChecked data-testid="checkbox" />
        )}
      </div>
      {label && (
        <div className={`${styles.label} ${labelClassName}`}>
          {label}
        </div>
      )}
    </div>
  )
}