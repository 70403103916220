import React from 'react';
import styles from './Steps.module.scss';
import { ReactComponent as RepairImage } from './Images/repair.svg';

export default function StepOne({
  containerProps: { className, ...containerProps } = {},
}: {
  containerProps?: Omit<React.HTMLAttributes<HTMLDivElement>, 'children'>;
}) {

  return (
    <div className={[styles.container, className].join(" ")} {...containerProps}>

      <div className={styles.text}>
        Grâce à votre contrat d’assurance, vous bénéficiez de solutions de mobilité suite à l’immobilisation de votre véhicule
      </div>

      <RepairImage />

    </div>
  )
}