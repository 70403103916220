import styles from './LoginWithLicensePlate.module.scss';
import PageContainer from 'src/layout/Page/PageContainer/PageContainer';
import HeaderSelfCare from 'src/layout/Header/Header.selfcare';
import InputText from '@components/Inputs/InputText/InputText';
import Button from '@components/Button/Button';
import { useState } from 'react';
import FooterLinks from 'src/layout/SelfCare/Footer/FooterLinks';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { publicApi } from 'src/Api/api';
import { AxiosError, AxiosResponse, isAxiosError } from 'axios';
import FullScreenLoader from '@components/FullScreenLoader/FullScreenLoader';
import { MissionType } from 'src/models/Mission';
import { MissionContextType } from 'src/Stores/CurrentMissionTypes/MissionContextType';
import { useAuthStoreLicensePlate } from 'src/Stores/AuthStoreLicensePlate';

export default function LoginWithLicensePlate() {

  const { token } = useParams();
  const navigate = useNavigate();

  const { setAuthenticatedSelfCareLicencePlace } = useAuthStoreLicensePlate();

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search);
  const licensePlate = searchParams.get('licensePlate');
  const flow = searchParams.get('flow');
  const [loading, setLoading] = useState<boolean>(false);
  const [licencePlate, setLicensePlate] = useState<string>(licensePlate || "");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const onSubmit = async () => {
    setErrorMessage("");
    setLoading(true);

    let response: AxiosResponse | null | void = null;

    try {
      response = await publicApi.request({
        method: 'POST',
        url: '/auth/self-care',
        data: {
          licencePlate,
          token
        }
      }).catch((error) => {
        if (!isAxiosError(error)) {
          setErrorMessage("Plaque d’immatriculation invalide");
          throw error;
        }
        const axiosError = error as AxiosError;
        if (axiosError.response?.status === 401) {
          response = axiosError.response;
          return;
        }
        setErrorMessage("Plaque d’immatriculation invalide");
        throw error;
      });
    } catch (e) {
      console.log(e);
      setLoading(false);
      return;
    }

    setAuthenticatedSelfCareLicencePlace(true);

    try {

      if (!response) {
        throw new Error("No response");
      }

      const { data } = response as AxiosResponse<{
        context: MissionContextType | null;
        mission: MissionType | null;
      }>;

      if (!data.mission || !data.context) {
        throw new Error("No mission or missionContext");
      }

      const { mission } = data;

      // Navigate to cases/:caseId/missions/:missionId
      let redirectUrl = `/self-care/cases/${mission.caseID}/missions/${mission.id}`;

      switch (flow) {
        case 'refund':
          redirectUrl += '/refund';
          break;
        default:
          break;
      }
      navigate(redirectUrl);
      setLoading(false);

    } catch (e) {
      console.log(e);
      setLoading(false);
      return;
    }
  }

  return (
    <PageContainer
      containerProps={{
        className: [styles.container, 'selfcare'].join(" "),
      }}
    >

      <HeaderSelfCare />

      {loading && <FullScreenLoader />}

      <div className={styles.content}>

        <div className={styles.title}>
          Veuillez saisir votre plaque d’immatriculation pour accéder à l’application Selfcare
        </div>

        <div className={styles.formContainer}>
          <InputText
            errorMsg={errorMessage}
            readOnly={false}
            inputProps={{
              placeholder: "Plaque d’immatriculation",
              value: licencePlate,
              onChange: (e) => setLicensePlate(e.target.value),
            }}
            containerProps={{
              className: 'w-full',
            }}
          />
        </div>

        <div className={styles.actions}>
          <Button
            label="Valider"
            onClick={onSubmit}
            type="primary"
            containerProps={{
              className: styles.actionButton,
            }}
          />
        </div>

      </div>

      <div className={styles.footerContainer}>
        <FooterLinks
          containerProps={{
            className: styles.footer
          }}
        />
      </div>

    </PageContainer>
  )
}

