import { Chevron, Close } from '@assets/icons/icons';
import styles from './Select.module.scss';
import React, { useRef } from 'react';
import { useOnClickOutside, useEventListener } from 'usehooks-ts';


function useDebounceSearchKeyDown({
  enable,
  debounceTime,
  onTriggerSearch,
}: {
  enable: boolean;
  debounceTime: number;
  onTriggerSearch?: (search: string) => any;
}) {

  const searchString = useRef("");
  const timeout = useRef<any>(null);

  useEventListener("keydown", (event) => {

    if (!enable) {
      return;
    }

    if (/^[a-zA-Z]{1}$/.test(event.key)) {
      searchString.current += event.key;
    } else {
      searchString.current = "";
    }

    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    timeout.current = setTimeout(() => {
      onTriggerSearch && onTriggerSearch(searchString.current);
      searchString.current = "";
    }, debounceTime);
  });
}


export type SelectProps = {
  selectedIndex?: number | null;
  selectedIndexes?: Array<number>;
  options?: Array<React.ReactNode>
  optionsV2?: Array<{
    value: React.ReactNode;
    label?: React.ReactNode;
    searchValue?: string;
    displaySelectedValue?: React.ReactNode;
  }>;
  onChangeIndex?: (selectedIndex: number | null) => any;
  onChangeIndexes?: (selectedIndexes: Array<number>) => any;
  placeholder?: string;
  allowRemove?: boolean;
  allowMultiple?: boolean;
  label?: string;
  testid?: string;
  errorMsg?: string;
  disabled?: boolean;
  layout?: "default" | "minimal";
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}

export default function Select({
  selectedIndex,
  selectedIndexes,
  options: optionsV1,
  optionsV2,
  onChangeIndex,
  onChangeIndexes,
  placeholder,
  allowRemove,
  allowMultiple,
  label,
  testid,
  errorMsg,
  disabled,
  layout = "default",
  containerProps: { className, ...containerProps } = {},
}: SelectProps) {

  const options = optionsV2 || (optionsV1 || []).map((option) => {
    return {
      value: option,
      label: option,
      searchValue: "",
      displaySelectedValue: option,
    }
  });

  const ref = useRef(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const optionsListRef = useRef<HTMLDivElement>(null);

  const [isOpen, setIsOpen] = React.useState(false);
  let selectedValue: React.ReactNode | null = null;
  if (allowMultiple) {
   // selectedValue = `${placeholder} (${selectedIndexes?.length})`;
    selectedValue = (
      <div className={styles.selectWithBadgeContainer}>
        <div className={styles.valueText}>{placeholder}</div>
        <div className={styles.badge}>
          <div className={styles.badgeText}>{selectedIndexes?.length}</div>
        </div>
      </div>
    )
  } else {
    selectedValue = (selectedIndex !== null && selectedIndex !== undefined && selectedIndex !== -1) ? (
      options[selectedIndex]?.displaySelectedValue || options[selectedIndex]?.label || options[selectedIndex]?.value
    ) : null;
  }

  const scrollToSelected = (selectedIndex: number | null | undefined) => {
    const optionsListElement = optionsListRef.current;
    if (optionsListElement && selectedIndex !== null && selectedIndex !== undefined && selectedIndex !== -1) {
      const selectedItem = optionsListElement.children[selectedIndex];
      selectedItem.scrollIntoView({
        block: "nearest",
        inline: "nearest",
      });
    }
  }

  const selectIndex = (index: number | null) => {
    if (index === selectedIndex) return;
    onChangeIndex && onChangeIndex(index);
    onChangeIndex && scrollToSelected(index);
  }

  useOnClickOutside(ref, () => {
    setIsOpen(false);
  });

  useEventListener("keydown", (event) => {

    if (!isOpen) return;

    event.stopPropagation();
    event.preventDefault();
    if (event.key === "Escape") {
      setIsOpen(false);
    }
    if (event.key === "ArrowDown") {
      if (typeof selectedIndex === 'number' && selectedIndex < options.length - 1) {
        selectIndex(selectedIndex + 1);
      } else {
        selectIndex(0);
      }
    }
    if (event.key === "ArrowUp") {
      if (typeof selectedIndex === 'number' && selectedIndex > 0) {
        selectIndex(selectedIndex - 1);
      } else {
        selectIndex(options.length - 1);
      }
    }

    if (event.key === "Enter") {
      if (typeof selectedIndex === 'number') {
        selectIndex(selectedIndex);
        openClose();
      }
    }

  });

  useDebounceSearchKeyDown({
    enable: isOpen,
    debounceTime: 200,
    onTriggerSearch: (search) => {
      if (!search) return;
      const optionIndex = options.findIndex((option) => {
        if (!option.searchValue) return false;
        return option.searchValue.toString().toLowerCase().startsWith(search);
      });
      if (optionIndex !== -1) {
        selectIndex(optionIndex);
      }
    }
  });

  const openClose = () => {
    if (disabled) return;
    setIsOpen(!isOpen);
    if (!isOpen) {
      inputRef.current?.focus();
      setTimeout(() => {
        scrollToSelected(selectedIndex)
      }, 0);
    }
  }

  return (
    <div
      ref={ref}
      data-testid={testid}
      className={[
        styles.container,
        disabled ? styles.disabled : "",
        isOpen ? styles.isOpen : "",
        styles[`layout_${layout}`] || "",
        className
      ].join(" ")}
      {...containerProps}
      onClick={() => {
        openClose();
      }}
    >

      {(label || errorMsg) && (
        <div className={styles.labelContainer}>
          {label && <div className={styles.label}>{label}</div>}
          {errorMsg && <div className={styles.errorMsg}>{errorMsg}</div>}
        </div>
      )}


      <div className={styles.input}>
        <div className={styles.value}>{selectedValue || (
          <span className={styles.placeholder}>{placeholder}</span>
        )}</div>
        {allowRemove && selectedIndex !== null && (
          <Close width={14} className={styles.removeIcon} onClick={(e) => {
            e.stopPropagation();
            onChangeIndex && onChangeIndex(null);
          }} />
        )}
        <Chevron width={14}/>
      </div>

      <div ref={optionsListRef} className={styles.options} data-testid={"options"}>
        {options.map((option, index) => {

          let isSelected = false;
          if (allowMultiple) {
            isSelected = selectedIndexes?.includes(index) || false;
          } else {
            isSelected = selectedIndex === index;
          }
          return (
            <div
              key={index}
              className={[styles.option, isSelected ? styles.selected : ""].join(" ")}
              onClick={() => {
                if (allowMultiple) {
                  if (selectedIndexes?.includes(index)) {
                    onChangeIndexes && onChangeIndexes(selectedIndexes.filter(i => i !== index));
                  } else {
                    onChangeIndexes && onChangeIndexes([...(selectedIndexes || []), index]);
                  }
                } else {
                  selectIndex(index);
                }
              }}
            >
              {option.label || option.value}
            </div>
          );
        })}
      </div>
    </div>
  )
}