import { Chevron } from '@assets/icons/icons';
import styles from './ExpandableText.module.scss';

export default function ExpandableTitle({
  title,
  expanded,
  allClickable = false,
  toggleExpanded,
  containerProps: { className } = {},
}: {
  title: string;
  // Add props to control if we can click on all the title or only on the arrow
  allClickable?: boolean;
  expanded: boolean,
  toggleExpanded: () => void;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}) {
  return (
    <div className={[className, styles.primaryContent].join(" ")} onClick={() => allClickable ? toggleExpanded() : null}>
      <div
        onClick={() => toggleExpanded()}
        className={[styles.develop, expanded ? styles.developActiv : ""].join(" ")}
      >
        <Chevron />
      </div>
      {title}

    </div>
  )
}