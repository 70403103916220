import React, { useEffect } from 'react';
import styles from './FeedbackWithStars.module.scss';
import { Star, StarSelected } from '@assets/icons/icons';

export default function FeedbackWithStars({
  loading = false,
  selectedStar: selectedStarProps = null,
  onClickStar,
  containerProps: { className, ...containerProps } = {},
}: {
  loading?: boolean;
  selectedStar?: number | null;
  onClickStar?: (star: number | null) => void;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}) {
  const stars = [1, 2, 3, 4, 5];
  const [selectedStar, setSelectedStar] = React.useState<number | null>(selectedStarProps);

  useEffect(() => {
    setSelectedStar(selectedStarProps);
  }, [selectedStarProps]);

  return (
    <div className={[styles.container, className].join(" ")} {...containerProps}>

      <div className={styles.text}>
        Comment s’est passé votre parcours de réservation ? de 1 étoile (mauvaise) à 5 étoiles (très bonne)
      </div>

      <div className={styles.starsContainer}>
        {stars.map((star) => (
          <div
            key={star}
            className={`${styles.starContainer} ${selectedStar !== null && selectedStar >= star ? styles.selected : ""}`}
            onClick={() => {
              if (loading || selectedStarProps !== null) {
                return;
              }
              if (selectedStar === star) {
                onClickStar?.(null);
                setSelectedStar(null);
              } else {
                onClickStar?.(star);
                setSelectedStar(star);
              }
            }}
          >
            {selectedStar !== null && selectedStar >= star ? <StarSelected /> : <Star />}
          </div>
        ))}
      </div>

      {selectedStar !== null && (
      <div className={styles.confirmText}>
        Votre note a bien été enregistrée
      </div>
      )}

    </div>
  )
}