import styles from './RecapItinerary.module.scss';

import formatDuration from 'src/helpers/duration';
import CabTravelPath from '../../CabTravelPath';
import Tag from '@components/Tag/Tag';
import { statusLabels } from 'src/helpers/statusLabels'
import RecapTravelPathSection from '../RecapTravelPathSection/RecapTravelPathSection';
import RecapTravelPathSectionStyles from '../RecapTravelPathSection/RecapTravelPath.module.scss';
import { ConfirmSectionType } from 'src/Stores/CurrentMissionTypes/MissionContextType.ts';
import {PriceType} from "../../../../../../models/Price.ts";

function PriceAndStatus({
  section
}: {
  section: ConfirmSectionType;
}) {

  if (!section.price || section.price.amount === 0) {
    return null;
  }
  return (
    <div className={styles.priceContainer}>
      {section.statusWithLabel.isDisplayed && statusLabels[section.statusWithLabel.id] && (
      <Tag
        label={section.statusWithLabel.label}
        color={statusLabels[section.statusWithLabel.id].color}
        containerProps={{ className: styles.tagRecapStatus}}
      />
      )}
      <div className={styles.price}>
        {section.priceIsEstimate ? "Estimation de " : ""}{section.price.formatted} {section.price.currency.symbol}
      </div>
    </div>
  )
}

function RightContent({
  section,
  displayStatusAndPrice = true
}: {
  section: ConfirmSectionType;
  displayStatusAndPrice?: boolean;
}) {
  return (
    <div className={RecapTravelPathSectionStyles.travelInfoContainer}>
      <div className={RecapTravelPathSectionStyles.textInfosContainer}>
        {section.type === "train" && (
          <div className={RecapTravelPathSectionStyles.infoPrimaryLabel}>
            {section.label}
          </div>
        )}
        <div className={RecapTravelPathSectionStyles.infoSecondaryLabel}>
          {formatDuration({
            unit: 'minutes',
            value: section.durationMinutes
          })}
        </div>
      </div>

      {displayStatusAndPrice && (
      <PriceAndStatus
        section={section}
      />
      )}
    </div>
  )
}

export default function RecapItinerary({
  sections: stepSections, trains
}: {
  sections: ConfirmSectionType[];
  trains?: {
    [key: string]: {
      reference?: string;
      totalPrice: PriceType;
      trainID: string;
    }
  }
}) {

  const sections: React.ReactNode[] = [];

  let trainSectionsMerge: React.ReactNode[] = [];
  let trainSectionsMergeInfos: React.ReactNode = null;

  for (let sectionIndex = 0; sectionIndex < stepSections.length; sectionIndex++) {
    const section = stepSections[sectionIndex];

    if (section.type !== "train" && trainSectionsMerge.length > 0) {
      sections.push(
        <div key={`train-merge-${sectionIndex}`} className={styles.trainSections}>
          <div>
          {trainSectionsMerge}
          </div>
          <div>
            {trainSectionsMergeInfos}
          </div>
        </div>
      );
      trainSectionsMerge = [];
    }

    if (section.type === "vrental") {
      continue;
    }

    if (section.type === "taxi") {
      sections.push(
        <CabTravelPath
          key={sectionIndex}
          section={section}
          containerProps={{
            className: styles.travelPath,
          }}
        />
      )
    } else {
      const recapSection = <RecapTravelPathSection
        key={sectionIndex}
        section={section}
        containerProps={{
          className: styles.travelPath,
        }}
        right={<RightContent section={section} displayStatusAndPrice={section.type !== "train"} />}
      />

      if (section.type === "train") {
        trainSectionsMerge.push(recapSection);
        section.price = (trains ?? {})[section.trainID ?? '']?.totalPrice || section.price;
        trainSectionsMergeInfos = (
          <PriceAndStatus section={section} />
        );
      } else {
        sections.push(
          recapSection
        );
      }
    }

  }

  if (trainSectionsMerge.length > 0) {
    sections.push(
      <div key={`train-merge-last`} className={styles.trainSections}>
        <div>
        {trainSectionsMerge}
        </div>
        <div>
          {trainSectionsMergeInfos}
        </div>
      </div>
    );
  }



  return (
    <div className={styles.recapItinerary}>
      {sections}
    </div>
  )
}