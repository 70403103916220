import styles from './ContractForm.module.scss';
import stylesForm from '../Form.module.scss';
import { useCurrentMission } from 'src/Stores/CurrentMission';
import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import Select from '@components/Select/Select';
import { useEffect, useState } from 'react';
import { authenticatedApi } from 'src/Api/api';

type FormData = {
  contract: number | null;
  eventOutcome: number | null;
  subcover: number | null;
};

export default function ContractForm({
  editable,
  onChangeEditState,
  layout = {
    case: true,
    beneficiaryName: true,
    beneficiaryPhone: true,
    licensePlate: true,
  },
  testid,
  containerProps: { className, ...containerProps } = {},
}: {
  editable?: boolean;
  onChangeEditState?: ({
    isEditing,
    isLoading,
  }: {
    isEditing: boolean;
    isLoading: boolean;
  }) => any;
  layout?: {
    case?: boolean;
    beneficiaryName?: boolean;
    beneficiaryPhone?: boolean;
    licensePlate?: boolean;
  };
  testid?: string;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}) {

  const [isLoading, setIsLoading] = useState(false);

  const { file, mission, fileCaseContractForm, setMission, setMissionContext } = useCurrentMission();
  const navigate = useNavigate();
  const contracts = fileCaseContractForm;

  const defaultValues = {
    contract: mission?.contractId || null,
    eventOutcome: mission?.eventOutcomeId || null,
    subcover: mission?.subcoverId || null,
  }

  const form = useForm<FormData>({
    defaultValues,
  });
  const { control, formState: { isDirty }, watch, reset } = form;

  const contractId = watch("contract");
  const eventOutcomeId = watch("eventOutcome");

  const eventOutcomes = (contracts || []).find((contract) => contract.id === contractId)?.eventOutcomes || [];
  const subcovers = (contracts || []).find((contract) => contract.id === contractId)?.eventOutcomes.find((eventOutcome) => eventOutcome.id === eventOutcomeId)?.subcovers || [];

  useEffect(() => {
    if (onChangeEditState) {
      onChangeEditState({
        isEditing: isDirty,
        isLoading,
      });
    }
  }, [isDirty, isLoading, onChangeEditState])

  const submitAction = form.handleSubmit(async (data) => {
    if (!form.formState.isDirty) {
      return false;
    }

    if (!mission?.updateAction) {
      return false;
    }

    setIsLoading(true);

    try {
      const response = await authenticatedApi.request({
        url: mission.updateAction.href,
        method: mission.updateAction.method,
        data: {
          contractID: data.contract,
          eventOutcomeID: data.eventOutcome,
          subcoverID: data.subcover,
        }
      });
      if (!response.data.mission || !response.data.context) {
        throw new Error("missing mission in response");
      }
      setMission(response.data.mission);
      setMissionContext(response.data.context);
      reset(data);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }

  });

  if (!file || !mission) {
    return null;
  }

  return (
    <div className={[styles.container, stylesForm.form, className].join(" ")} {...containerProps} data-testid={testid}>

      {layout.case && (
      <div className={stylesForm.formRow}>
        <div className={stylesForm.formCol}>N° de dossier <span className={stylesForm.formValueLeft}>{file.id}</span></div>
        <div className={stylesForm.formCol}><a onClick={(e) => {
          e.preventDefault();
          navigate("/");
          onChangeEditState && onChangeEditState({
            isEditing: true,
            isLoading,
          });
          return false;
        }} className={`${stylesForm.formLink} ${styles.formLink}`}>Changer de dossier</a></div>
      </div>
      )}

      {layout.case && (
      <div className={stylesForm.formRow}>
        <div className={stylesForm.formCol}>N° de mission <span className={stylesForm.formValueLeft}>{mission.id}</span></div>
        <div className={stylesForm.formCol}><a onClick={(e) => {
          e.preventDefault();
          navigate("/cases/" + file.id);
          onChangeEditState && onChangeEditState({
            isEditing: true,
            isLoading,
          });
          return false;
        }} data-testid='button-change-mission' className={`${stylesForm.formLink} ${styles.formLink}`}>Changer de mission</a></div>
      </div>
      )}

      {layout.beneficiaryName && (
      <div className={stylesForm.formRow}>
        <div className={stylesForm.formCol}>Nom du bénéficiaire</div>
        <div className={stylesForm.formCol}>{`${file.clientFirstname} ${file.clientLastname}`}</div>
      </div>
      )}

      {layout.beneficiaryPhone && (
      <div className={stylesForm.formRow}>
        <div className={stylesForm.formCol}>N° de téléphone  mobile</div>
        <div className={stylesForm.formCol}>{file.clientPhone ? file.clientPhone.split(" ").slice(1).join("") : ""}</div>
      </div>
      )}

      {layout.licensePlate && (
      <div className={stylesForm.formRow}>
        <div className={stylesForm.formCol}>Plaque d'immatriculation</div>
        <div className={stylesForm.formCol}>{file.licensePlate}</div>
      </div>
      )}

      {editable && (
        <table className='w-full'>
          <tbody className='w-full'>
            <tr>
              <td className={`${stylesForm.formCol} ${styles.tableRowLabel}`}>Contrat</td>
              <td>
                <Controller
                  name="contract"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Le numéro de contrat est obligatoire"
                    }
                  }}
                  render={({ field }) => (
                    <Select
                      disabled={isLoading}
                      placeholder='Choisir un contrat'
                      options={(contracts || []).map((contract) => contract.label)}
                      selectedIndex={(contracts || []).findIndex((contract) => contract.id === field.value)}
                      onChangeIndex={(selectedIndex) => {
                        if (selectedIndex === null) {
                          field.onChange(null);
                        } else if (contracts && contracts.length > 0) {
                          field.onChange(contracts[selectedIndex].id);
                        }
                        form.setValue("eventOutcome", null);
                        form.setValue("subcover", null);
                      }}
                      containerProps={{
                        className: "w-full",
                      }}
                    />

                  )}
                />
              </td>
            </tr>

            <tr>
              <td className={`${stylesForm.formCol} ${styles.tableRowLabel}`}>Evènement</td>
              <td>
                <Controller
                  name="eventOutcome"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "L'évènement est obligatoire"
                    }
                  }}
                  render={({ field }) => (
                    <Select
                      disabled={isLoading}
                      placeholder='Choisir une cause'
                      options={eventOutcomes.map((eventOutcome) => eventOutcome.label)}
                      selectedIndex={eventOutcomes.findIndex((eventOutcome) => {
                        return eventOutcome.id === field.value
                      })}
                      onChangeIndex={(selectedIndex) => {
                        if (selectedIndex === null) {
                          field.onChange(null);
                        } else {
                          field.onChange(eventOutcomes[selectedIndex].id);
                        }
                        form.setValue("subcover", null);
                      }}
                      containerProps={{
                        className: "w-full",
                      }}
                    />

                  )}
                />
              </td>
            </tr>

            <tr>
              <td className={`${stylesForm.formCol} ${styles.tableRowLabel}`}>Garantie</td>
              <td width={250}>
                <Controller
                  name="subcover"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "La garantie est obligatoire"
                    }
                  }}
                  render={({ field }) => (
                    <Select
                      disabled={isLoading}
                      placeholder='Selectionnez la garantie'
                      options={subcovers.map((subcover) => subcover.label)}
                      selectedIndex={subcovers.findIndex((subcover) => subcover.id === field.value)}
                      onChangeIndex={(selectedIndex) => {
                        if (selectedIndex === null) {
                          field.onChange(null);
                          return;
                        }
                        field.onChange(subcovers[selectedIndex].id);
                        setTimeout(() => {
                          submitAction();
                        }, 0)
                      }}
                      containerProps={{
                        className: "w-full",
                      }}
                    />

                  )}
                />
              </td>
            </tr>
          </tbody>
        </table>
      )}

      {!editable && (
        <>
          <div className={stylesForm.formRow}>
            <div className={stylesForm.formCol}>Contrat</div>
            <div className={stylesForm.formCol}>{mission.contract}</div>
          </div>

          <div className={stylesForm.formRow}>
            <div className={stylesForm.formCol}>Evènement</div>
            <div className={stylesForm.formCol}>{mission.eventOutcome}</div>
          </div>

          <div className={stylesForm.formRow}>
            <div className={stylesForm.formCol}>Garantie</div>
            <div className={stylesForm.formCol}>{mission.subcover}</div>
          </div>
        </>
      )}
    </div>
  )
}