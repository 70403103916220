import React from 'react';
import styles from './Steps.module.scss';
import { Cab, Car, CheckValid, IconEuroSign, IconLight, Train, Warranty } from '@assets/icons/icons';

export default function StepOffers({
  containerProps: { className, ...containerProps } = {},
}: {
  containerProps?: Omit<React.HTMLAttributes<HTMLDivElement>, 'children'>;
}) {

  return (
    <div className={[styles.container, className].join(" ")} {...containerProps}>

      <div className={styles.text}>
        Découvrez vos offres de transport disponibles
      </div>

      <div className={styles.offersExample}>
        <div className={styles.text}>
          Certaines offres peuvent entrainer un reste à charge selon vos conditions contractuelles.
        </div>

        <div className={styles.offerExample}>
          <Train />
          <div className={styles.offerInfos}>
            <div className={styles.offerName}>Train</div>
            <div>Sous conditions</div>
          </div>
          <CheckValid />
        </div>

        <div className={styles.separator} />

        <div className={styles.offerExample}>
          <Car />
          <div className={styles.offerInfos}>
            <div className={styles.offerName}>Véhicule de location</div>
            <div>Sous conditions</div>
          </div>
          <CheckValid />
        </div>

        <div className={styles.separator} />

        <div className={styles.offerExample}>
          <Cab />
          <div className={styles.offerInfos}>
            <div className={styles.offerName}>Taxi</div>
            <div>Sous conditions</div>
          </div>
          <IconEuroSign />
        </div>
      </div>

      <div className={styles.offerInfosAdvantages}>
        <IconLight />
        <div className={styles.offerInfosAdvantagesText}>
          Consultez vos avantages à n'importe quel moment en cliquant dans l'en-tête sur cette icône
        </div>
        <Warranty />
      </div>

    </div>
  )
}