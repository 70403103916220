import layoutStyles from '../../CurrentMission.selfcare.module.scss';
import { useCurrentMission } from 'src/Stores/CurrentMission';
import SearchForm from 'src/Pages/_Components/SearchForm/SearchForm';
import styles from './SearchItinerary.selfcare.module.scss';
import PageContainer from 'src/layout/Page/PageContainer/PageContainer';
import { useState } from 'react';
import FullScreenLoader from '@components/FullScreenLoader/FullScreenLoader';
import HeaderSelfCare from 'src/layout/Header/Header.selfcare';
import { useNavigate } from 'react-router-dom';

export default function SearchItinerary() {

  const navigate = useNavigate();
  const { mission } = useCurrentMission();
  const [loadingSearch, setLoadingSearch] = useState(false);

  if (!mission) {
    return null;
  }

  return (
    <PageContainer
      containerProps={{
        className: [layoutStyles.container].join(" "),
      }}
    >

      {loadingSearch && (
        <FullScreenLoader />
      )}

      <HeaderSelfCare
        title='Vos critères de recherche'
        displayBackButton
        displayMissionProgressBar
        onBackButtonClick={() => {
          navigate("../");
        }}
      />

      <div className={layoutStyles.content}>

        <div className={styles.colFormContainer}>

          <div className={styles.searchFormContainer}>
            <SearchForm
              onChangeSearchLoading={(isLoading) => {
                setLoadingSearch(isLoading);
              }}
              containerProps={{
                className: [styles.searchForm].join(" "),
              }}

              options={{
                driver: {
                  disabled: true,
                },
                options: {
                  disabled: true,
                },
                passenger: {
                  canAddCard: false,
                },
                noRemainingCosts: {
                  hide: true,
                  defaultValue: false,
                  canChange: false,
                }
              }}
            />
          </div>

        </div>


      </div>

    </PageContainer>
  )
}