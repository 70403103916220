import { SolutionType, StepSolutions } from "src/Stores/CurrentMissionTypes/MissionContextType";

import { FilterItem, FilterTypeMultiValues, FilterTypeMultiCheckboxes, FilterTypeSwitch } from "../types";
import useFilter, { UseFilterReturnType } from "../useFilter";
import { useMemo } from "react";


type SolutionFiltersType = {
  energy: FilterTypeMultiValues;
  category: FilterTypeMultiValues;
  transmission: FilterTypeMultiValues;
  mainTransportType: FilterTypeMultiCheckboxes;
  hasRemainingCosts: FilterTypeSwitch;
}

function getItemValuesFromVrentalFilter(item: SolutionType, vrentalKey: string): Array<string> {
  const valuesObj: { [key: string]: boolean } = {};
  item.sections.forEach((section) => {
    (section.vrentals || []).forEach((vrental) => {
      if (!vrental.filterValues || !vrental.filterValues[vrentalKey]) {
        return;
      }
      const filterValue = vrental.filterValues[vrentalKey];

      if (typeof filterValue === 'string') {
        valuesObj[filterValue] = true;
      } else {
        filterValue.forEach((v) => {
          valuesObj[v] = true;
        });
      }
    });
  });

  return Object.keys(valuesObj);
}

function itemMatchesValuesForVrentalKey(item: FilterItem, filterItemKey: string, values: { [key: string]: boolean }) {

  const filterItemValues = item.dynamicFilters?.[filterItemKey]?.value as string[];
  if (!filterItemValues) {
    return false;
  }

  // If no values selected, we want to show all items
  if (Object.keys(values).filter((value) => values[value]).length === 0) {
    return true;
  }

  return filterItemValues.some((value: string) => {
    return values[value];
  });
}

const filtersConfig: SolutionFiltersType = {
  energy: {
    type: 'multiselect',
    label: "Energy",
    options: [],
    displayTags: true,
    getItemValues: (item: SolutionType) => {
      return {
        type: 'multi',
        value: getItemValuesFromVrentalFilter(item, 'energy')
      };
    },
    itemMatchesValues: (item: FilterItem, values: { [key: string]: boolean }) => {
      if (!(item.dynamicFilters?.mainTransportType?.value as string[]).includes('vrental')) {
        return true;
      }
      return itemMatchesValuesForVrentalKey(item, 'energy', values);
    }
  },

  category: {
    type: 'multiselect',
    displayTags: true,
    label: "Category",
    options: [],
    getItemValues: (item: SolutionType) => {
      return {
        type: 'multi',
        value: getItemValuesFromVrentalFilter(item, 'category')
      };
    },
    itemMatchesValues: (item: FilterItem, values: { [key: string]: boolean }) => {
      if (!(item.dynamicFilters?.mainTransportType?.value as string[]).includes('vrental')) {
        return true;
      }
      return itemMatchesValuesForVrentalKey(item, 'category', values);
    }
  },

  transmission: {
    type: 'multiselect',
    displayTags: true,
    label: "Transmission",
    options: [],
    getItemValues: (item: SolutionType) => {
      return {
        type: 'multi',
        value: getItemValuesFromVrentalFilter(item, 'transmission')
      };
    },
    itemMatchesValues: (item: FilterItem, values: { [key: string]: boolean }) => {
      if (!(item.dynamicFilters?.mainTransportType?.value as string[]).includes('vrental')) {
        return true;
      }
      return itemMatchesValuesForVrentalKey(item, 'transmission', values);
    }
  },

  mainTransportType: {
    type: 'multicheckboxes',
    displayTags: false,
    required: true,
    options: [
      {
        label: "Taxi",
        value: "taxi",
      },
      {
        label: "Train",
        value: "train",
      },
      {
        label: "Véhicule de location",
        value: "vrental",
      },
    ],
    defaultSelectedValues: {
      taxi: true,
      train: true,
      vrental: true,
    },
    getItemValues: (item: SolutionType) => {
      return {
        type: 'multi',
        value: [item.mainTransportType.id]
      };
    },
    itemMatchesValues: (item: FilterItem, values: { [key: string]: boolean }) => {

      for (const value of Object.keys(values)) {
        if (values[value] && (item.dynamicFilters?.mainTransportType?.value as string[]).includes(value)) {
          return true;
        }
      }
      return false;
    }
  },

  hasRemainingCosts: {
    type: 'switch',
    label: "Afficher résultats avec reste à charge",
    labelTag: "Avec reste à charge",
    displayTags: false,
    getItemValues: (item: SolutionType) => {
      return {
        type: 'switch',
        value: !!item.remainingCosts?.amount,
      };
    },
    itemMatchesValues: (item: FilterItem, value: boolean) => {
      // If checked, we want all items
      if (value) {
        return true;
      }
      // If not checked, we don't want item that have remaining costs
      return !item.dynamicFilters.hasRemainingCosts.value
    },
    defaultSelectedValue: true,
  }
}

export default function useFilterSolutions(
  solutions?: StepSolutions,
  options?: {
    disabledFilters?: Array<keyof SolutionFiltersType>
  }
): UseFilterReturnType {

  const filters: SolutionFiltersType = useMemo(() => {

    const disabledFilters = options?.disabledFilters || [];

    const energy: SolutionFiltersType['energy'] = {
      ...filtersConfig.energy,
      ...(solutions?.filters?.vrental?.energy ? {
        label: solutions.filters.vrental.energy.label,
        options: solutions.filters.vrental.energy.options,
        defaultSelectedValues: solutions.filters.vrental.energy.selectedValue ? {[solutions.filters.vrental.energy.selectedValue]: true}:{},//mapToBooleanTrue(solutions.filters.vrental.energy.options.map((option) => option.value)),
      } : {}),
      disabled: disabledFilters?.includes('energy') || !solutions?.filters?.vrental?.energy,
    };

    const category: SolutionFiltersType['category'] = {
      ...filtersConfig.category,
      ...(solutions?.filters?.vrental?.category ? {
        label: solutions.filters.vrental.category.label,
        options: solutions.filters.vrental.category.options,

        defaultSelectedValues: {},//mapToBooleanTrue(solutions.filters.vrental.category.options.map((option) => option.value)),
      } : {}),
      disabled: disabledFilters?.includes('category') || !solutions?.filters?.vrental?.category,
    };

    const transmission: SolutionFiltersType['transmission'] = {
      ...filtersConfig.transmission,
      ...(solutions?.filters?.vrental?.transmission ? {
        label: solutions.filters.vrental.transmission.label,
        options: solutions.filters.vrental.transmission.options,
        defaultSelectedValues: solutions.filters.vrental.transmission.selectedValue ? {[solutions.filters.vrental.transmission.selectedValue]: true}:{},//mapToBooleanTrue(solutions.filters.vrental.transmission.options.map((option) => option.value)),
      } : {}),
      disabled: disabledFilters?.includes('transmission') || !solutions?.filters?.vrental?.transmission,
    };

    const mainTransportDisabled = disabledFilters?.includes('mainTransportType') || (solutions?.solutions || []).map((solution) => solution.mainTransportType.id).filter((value, index, self) => self.indexOf(value) === index).length < 2
    return {
      mainTransportType: {
        ...filtersConfig.mainTransportType,
        disabled: mainTransportDisabled,
        required: mainTransportDisabled ? false : filtersConfig.mainTransportType.required,
      },
      hasRemainingCosts: {
        ...filtersConfig.hasRemainingCosts,
        disabled: disabledFilters?.includes('hasRemainingCosts') || filtersConfig.hasRemainingCosts.disabled,
      },
      category,
      energy,
      transmission,
    }
  }, [options?.disabledFilters, solutions?.filters?.vrental?.category, solutions?.filters?.vrental?.energy, solutions?.filters?.vrental?.transmission, solutions?.solutions])

  const useFilterValue = useFilter({
    items: solutions?.solutions || [],
    filters,
  });

  const allVrentalSolutionsDisabled = useMemo(() => {
    return filters.energy.disabled && filters.category.disabled && filters.transmission.disabled;
  }, [filters.category.disabled, filters.energy.disabled, filters.transmission.disabled]);

  return {
    ...useFilterValue,
    modalProps: {
      ...useFilterValue.modalProps,
      layout: [
        { type: 'filter', filterKey: 'mainTransportType' },
        { type: 'filter', filterKey: 'hasRemainingCosts' },
        ...(allVrentalSolutionsDisabled ? [] : [
          { type: 'title', title: "Filtres location de voiture" } as { type: 'title', title: string },
        ]),
        { type: 'filter', filterKey: 'category' },
        { type: 'filter', filterKey: 'energy' },
        { type: 'filter', filterKey: 'transmission' },
      ]
    }
  };
}

