import { useEffect, useMemo, useState } from "react";


function getDatePlus23Hours(date: Date) {
  const newDate = new Date(date);
  newDate.setHours(date.getHours() + 23);
  return newDate;
}

export default function useFormDates(searchForm?: {
  datetime: string;
  returnDatetime?: string;
}) {

  const currentDatePlus1Hour = useMemo(() => {
    //const date = new Date("2024-06-10T19:01:00");
    const date = new Date();
    date.setMinutes(date.getMinutes() + 30 - (date.getMinutes() % 15));
    return date;
  }, []);
  const currendDatePlus23HoursReturn = useMemo(() => {
    return getDatePlus23Hours(currentDatePlus1Hour);
  }, [currentDatePlus1Hour]);

  const [formDate, setFormDate] = useState<{
    date: Date;
    hour: number;
    minutes: number;
    dateReturn: Date | null;
    hourReturn: number | null;
    minutesReturn: number | null;
  }>({
    date: searchForm?.datetime ? new Date(searchForm?.datetime) : currentDatePlus1Hour,
    hour: searchForm?.datetime ? new Date(searchForm?.datetime).getHours() : currentDatePlus1Hour.getHours(),
    minutes: searchForm?.datetime ? new Date(searchForm?.datetime).getMinutes() : currentDatePlus1Hour.getMinutes(),
    dateReturn: searchForm?.returnDatetime ? new Date(searchForm?.returnDatetime) : currendDatePlus23HoursReturn,
    hourReturn: searchForm?.returnDatetime ? new Date(searchForm?.returnDatetime).getHours() : currendDatePlus23HoursReturn.getHours(),
    minutesReturn: searchForm?.returnDatetime ? new Date(searchForm?.returnDatetime).getMinutes() : currendDatePlus23HoursReturn.getMinutes(),
  });

  const minDateReturn = useMemo(() => {
    const date = new Date(formDate.date);
    date.setHours(formDate.hour);
    date.setMinutes(formDate.minutes);
    return getDatePlus23Hours(date);
  }, [formDate.date, formDate.hour, formDate.minutes]);

  useEffect(() => {
    if (formDate.dateReturn && formDate.dateReturn < minDateReturn) {
      setFormDate((prev) => ({
        ...prev,
        dateReturn: minDateReturn,
        hourReturn: minDateReturn.getHours(),
        minutesReturn: minDateReturn.getMinutes(),
      }));
    }
  }, [formDate.dateReturn, minDateReturn]);

  useEffect(() => {
    if (formDate.date < currentDatePlus1Hour) {
      setFormDate((prev) => ({
        ...prev,
        date: currentDatePlus1Hour,
        hour: currentDatePlus1Hour.getHours(),
        minutes: currentDatePlus1Hour.getMinutes(),
      }));
    }
  }, [currentDatePlus1Hour, formDate.date]);

  return {
    formDate,
    setFormDate,

    minDate: currentDatePlus1Hour,
    minHour: formDate.date?.getDate() === currentDatePlus1Hour.getDate() ? currentDatePlus1Hour.getHours() : 0,
    minMinutes: formDate.date?.getDate() === currentDatePlus1Hour.getDate() && formDate.date?.getHours() === currentDatePlus1Hour.getHours() ? currentDatePlus1Hour.getMinutes() : 0,

    minDateReturn,
    minHourReturn: formDate.dateReturn?.getDate() === minDateReturn.getDate() ? minDateReturn.getHours() : 0,
    minMinutesReturn: formDate.dateReturn?.getDate() === minDateReturn.getDate() && formDate.dateReturn?.getHours() === minDateReturn.getHours() ? minDateReturn.getMinutes() : 0,

  };
}