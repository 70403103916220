import styles from './NotCovered.module.scss';
import PageContainer from 'src/layout/Page/PageContainer/PageContainer';
import HeaderSelfCare from 'src/layout/Header/Header.selfcare';
import Button from '@components/Button/Button';
import { useCurrentMission } from 'src/Stores/CurrentMission';
import { useNavigate } from 'react-router-dom';
import { displayPrice } from 'src/helpers/price';

export default function NotCoveredStepReceiptSelfCare() {

  const navigate = useNavigate();
  const { missionContext } = useCurrentMission();
  const refundFlow = missionContext?.refundFlow;

  return (
    <PageContainer
      containerProps={{
        className: [styles.container, 'selfcare'].join(" "),
      }}
    >

      <HeaderSelfCare
        title={"Informations de remboursement"}
        displayBackButton={false}
      />

      <div className={styles.content}>

        <div className={`flex flex-col justify-center w-full`}>

          <div className={`flex flex-row justify-between align-center`} style={{ marginTop: "16px" }}>
            <div className={styles.labelTitle}>Prestation</div>
            <div className={styles.valueTitle}>{refundFlow?.benefits?.find(b => b.value === refundFlow?.selectedBenefit)?.name || ""}</div>
          </div>

          <div className={`flex flex-row justify-between align-center ${styles.labelValueBold}`} style={{ marginTop: "16px" }}>
            <div className={styles.labelTitle}>Montant remboursé *</div>
            <div className={styles.valueTitle}>{displayPrice(refundFlow?.coveredAmount)}</div>
          </div>

          <div className={`flex flex-row justify-between align-center`} style={{ marginTop: "16px" }}>
            <div className={styles.labelTitle}>Solde à votre charge</div>
            <div className={styles.valueTitle}>{displayPrice(refundFlow?.remainingCosts)}</div>
          </div>

          <div className={`flex flex-row justify-between align-center ${styles.lineConditions}`} style={{ marginTop: "16px" }}>
            * sous condition d’acceptation de votre demande
          </div>
        </div>

        <div className={styles.text} style={{ marginTop: "40px" }}>
          Nous regrettons de vous informer que cette prestation n'est pas prise en charge car elle n'est pas couverte par vos garanties.
        </div>


        <div className={styles.actions}>
          <Button
            label="Revenir à l'accueil"
            onClick={() => {
              navigate("/self-care");
            }}
            type="primary"
            containerProps={{
              className: styles.actionButton,
            }}
          />
        </div>

      </div>

    </PageContainer>
  )
}