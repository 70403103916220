import styles from "./FilterModal.module.scss";
import stylesForm from "@components/Form/Form.module.scss";
import Button from "@components/Button/Button";
import Modal from "@components/Modal/Modal";
import { Filter } from "@assets/icons/icons";
import { FilterConfigKeys, ModalProps } from "./filterTypes";
import Switch from "@components/Switch/Switch";
import Checkbox from "@components/Checkbox/Checkbox";
import Select from "@components/Select/Select";
import Tag from "./Tag/Tag";
import { isSelfCare } from "src/Stores/AppContext";

export default function FilterModal({
  isOpen,
  tags,
  filterConfig,
  onRequestClose,
  onClickApply,
  containerProps: { className, ...containerProps } = {},
}: ModalProps & {
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}) {

  if (!isOpen) {
    return null;
  }

  return (
    <Modal
      onRequestClose={onRequestClose}
      title={isSelfCare() ? "Filtres" : undefined}
      leftTitle={isSelfCare() ? undefined : (
        <div className={styles.filterTitleContainer}>
          <Filter />
          <div className={styles.filterTitleLabel}>Filtres</div>
        </div>
      )}
      containerProps={{
        className: styles.modal,
      }}
    >
      <div
        className={[
          stylesForm.form,
          styles.modalContainer,
          className,
        ].join(" ")}
        {...containerProps}
      >

        <div
          className={[
            styles.formContainer,
            className,
          ].join(" ")}
        >

          <div className={styles.tagsContainer}>
            {Object.keys(tags || {}).map((tagKey) => (
              <Tag
                key={tagKey}
                label={(tags || {})[tagKey].label}
                onClick={() => {
                  (tags || {})[tagKey].onClickRemove();
                }}
                containerProps={{
                  className: styles.tag,
                }}
              />
            ))}
          </div>

          {filterConfig ? (Object.keys(filterConfig) as FilterConfigKeys[]).map((filterKey) => {

            const filter = filterConfig[filterKey];
            if (!filter) {
              return null;
            }

            switch (filter.type) {
              case 'switch':
                return (
                  <div className={stylesForm.formRow} key={filterKey}>
                    <div className={[stylesForm.formCol].join(" ")}>
                      <span className={stylesForm.formLabelNormal}>
                        {filter.label}
                      </span>
                    </div>
                    <div className={[stylesForm.formCol].join(" ")}>
                      <Switch
                        checked={filter.value}
                        onChange={(checked) => {
                          filter.onChange(checked);
                        }}
                      />
                    </div>
                  </div>
                );

              case 'select':
                return (
                  <div className={stylesForm.formRow} key={filterKey}>
                    <div className={[stylesForm.formCol, styles.fullWidth].join(" ")}>
                      <Select
                        containerProps={{
                          className: styles.fullWidth,
                        }}
                        placeholder={filter.label}
                        allowMultiple
                        selectedIndexes={filter.options.map((option, index) => {
                          if (!filter.values) {
                            return null;
                          }
                          return filter.values[option.value] ? index : null;
                        }).filter((index) => index !== null) as number[]}
                        onChangeIndexes={(selectedIndexes) => {
                          const newValues: {
                            [key: string]: boolean;
                          } = {};
                          selectedIndexes.forEach((index) => {
                            newValues[filter.options[index].value] = true;
                          });
                          filter.onChange(newValues);
                        }}
                        optionsV2={filter.options.map((option) => ({
                          value: option.value,
                          label: option.label,
                          searchValue: option.label,
                        }))}
                      />
                    </div>
                  </div>
                );

              case 'multicheckboxes':
                return (
                  <div className={stylesForm.formRow} key={filterKey}>
                    <div className={[stylesForm.formCol, styles.checkboxesContainer].join(" ")}>
                      {filter.options.map((option) => (
                        <Checkbox
                          containerProps={{
                            className: styles.checkbox,
                          }}
                          key={option.value}
                          label={option.label}
                          checked={filter.values && filter.values[option.value]}
                          onChange={(checked) => {
                            filter.onChange({
                              ...filter.values,
                              [option.value]: checked,
                            });
                          }}
                        />
                      ))}
                    </div>
                  </div>
                );
              default:
                return null;
            }
          }) : null}

        </div>

        <Button
          label="Appliquer les filtres"
          containerProps={{
            className: [stylesForm.buttonSubmit, styles.buttonValidate].join(" "),
          }}
          onClick={onClickApply}
        />
      </div>
    </Modal>
  );
}
