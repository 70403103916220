import { useInterval } from 'usehooks-ts';
import styles from './Header.module.scss';
import { useState } from 'react';

export default function CurrentHour({
  userName,
  containerProps: { className, ...containerProps } = {},
}: {
  userName?: string;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}) {

  const getCurrentHour = () => {
    return (new Date()).toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' })
  }
  const [currentHour, setCurrentHour] = useState(getCurrentHour());

  useInterval(() => {
    setCurrentHour(getCurrentHour());
  }, 60000);


  return (
    <div className={[styles.currentHourContainer, className].join(" ")} {...containerProps}>
      <div className={styles.currentHour}>{currentHour}</div>
      <div className={styles.currentHourInfo}>
        Heure locale actuelle
      </div>
      {userName && (
        <div className={styles.username}>
          {userName}
        </div>
      )}
    </div>
  )
}